@charset 'UTF-8';
@use './modules/global/index' as *;
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@500&display=swap');

/* メインビジュアル */
.visual-wrapper {
  height: calc(100vh - 32vh);
  @include media(lg) {
    height: calc(100vh - 183px);
  }
  .top-slide {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .top-slide-container {
    text-align: center;
    .top-slide-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      animation: slider-1 24s linear infinite;
      &::after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.15);
      }
    }
    .top-slide-text {
      opacity: 0;
      animation: slider-2 24s linear infinite;
      color: var(--white);
      font-family: 'Noto Serif JP', serif;
      font-size: min(5vw,24px);
      letter-spacing: .1em;
      line-height: 150%;
      position: absolute;
      right: 0;
      left: 0;
      margin: 0 auto;
      @include media(lg) {
        font-size: min(3vw,40px);
      }
      .top-slide-br {
        @include media(lg) {
          display: none;
        }
      }
      .top-slide-br-lg {
        display: none;
        @include media(lg) {
          display: inline;
        }
      }
    }
    &.one {
      .top-slide-image {
        background-image: url("../../../images/top/top-slide/top-slide-m01.jpg");
        animation-delay: -2s;
        @include media(md) {
          background-image: url("../../../images/top/top-slide/top-slide01.jpg");
        }
        &::after {
          background: rgba(255,255,255,0.15);
        }
      }
      .top-slide-text {
        animation-delay: -2s;
        top: 25%;
        @include media(lg) {
          right: 4.5rem;
          left: initial;
          margin-right: initial;
          margin-left: auto;
        }
      }
    }
    &.two {
      .top-slide-image {
        background-image: url("../../../images/top/top-slide/top-slide-m02.jpg");
        animation-delay: 6s;
        @include media(md) {
          background-image: url("../../../images/top/top-slide/top-slide02.jpg");
        }
      }
      .top-slide-text {
        animation-delay: 6s;
        top: 13%;
      }
    }
    &.three {
      .top-slide-image {
        background-image: url("../../../images/top/top-slide/top-slide-m03.jpg");
        animation-delay: 14s;
        @include media(md) {
          background-image: url("../../../images/top/top-slide/top-slide03.jpg");
        }
      }
      .top-slide-text {
        animation-delay: 14s;
        top: 42%;
        @include media(lg) {
          right: 4rem;
          left: initial;
          margin-right: initial;
          margin-left: auto;
          text-align: left;
        }
      }
    }
  }
  
  @keyframes slider-1 {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    4.16% {
      opacity: 1;
    }
    33.33% {
      opacity: 1;
    }
    41.66% {
      opacity: 0;
      transform: scale(1.2);
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes slider-2 {
    0% {
      opacity: 0;
    }
    4.16% {
      opacity: 1;
    }
    33.33% {
      opacity: 1;
    }
    41.66% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

/* 各コンテンツ共有 */
.top-section-wrapper {
  padding: 3.5rem 1rem;
  background-color: #fafafa;
  &.top-link {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  &.caution {
    padding-top: 0;
    padding-bottom: 3.5rem;
  }
  &.news {
    padding-top: 0;
    padding-bottom: 0;
  }
  &.guide {
    background-color: #fff;
  }
  &.banner {
    background-color: #ddd;
    padding-right: 0;
    @include media(lg) {
      padding-right: 1rem;
    }
  }
  .top-inner-container {
    max-width: 1200px;
    margin: 0 auto;
    container-type: inline-size;
    &.white {
      background-color: var(--white);
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      @include media(lg) {
        padding-top: 5rem;
        padding-bottom: 5rem;
      }
    }
    /* 各コンテンツ共有(タイトル)*/
    .top-heading {
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: 1rem;
      width: 100%;
      @include media(lg) {
        font-size: 1.7rem;
        width: auto;
        margin-bottom: 0;
        margin-right: 3rem;
      }
      &.blue {
        color: var(--base);
      }
    }
  }
}

/* メインビジュアル下のボタン */
.top-link-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  .top-link-item {
    width: calc(50% - 0.5rem);
      &:nth-child(1){
        width: 100%;
      }
    .top-link-btn{
      background-color: white;
      padding: 1.5rem;
      box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      // Icon
      &::after {
        content: '';
        background-image: url("../../../images/top/top_link_btn/arrow.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        bottom: .5rem;
        right: 1rem;
        transform: translateY(-50%);
      }
      // hover アニメーション
      transition: ease .2s;
      overflow: hidden;
      //Icon befoer のカラー変更
      .top-link-btn-icon {
        height: 4rem;
        width: 4rem;
        margin-bottom: 1rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      &.btn01 .top-link-btn-icon {
      background-image: set-svg($link-01, #ce7272);
      }
      @include hover() {
        &.btn01 .top-link-btn-icon {
          background-image: set-svg($link-01, #fff);
        }
        &.btn02 .top-link-btn-icon {
          background-image: set-svg($link-02, #fff);
        }
        &.btn03 .top-link-btn-icon {
          background-image: set-svg($link-03, #fff);
        }
        &.btn04 .top-link-btn-icon {
          background-image: set-svg($link-04, #fff);
        }
        &.btn05 .top-link-btn-icon {
          background-image: set-svg($link-05, #fff);
        }
      }
      /*&.btn01:hover .top-link-btn-icon {
        background-image: set-svg($link-01, #fff);
      }*/
      &.btn02 .top-link-btn-icon {
        background-image: set-svg($link-02, #689e88);
      }
      /*&.btn02:hover .top-link-btn-icon {
        background-image: set-svg($link-02, #fff);
      }*/
      &.btn03 .top-link-btn-icon {
        background-image: set-svg($link-03, #5694ae);
      }
      /*&.btn03:hover .top-link-btn-icon {
        background-image: set-svg($link-03, #fff);
      }*/
      &.btn04 .top-link-btn-icon {
        background-image: set-svg($link-04, #bab767);
      }
      /*&.btn04:hover .top-link-btn-icon {
        background-image: set-svg($link-04, #fff);
      }*/
      &.btn05 .top-link-btn-icon {
        background-image: set-svg($link-05, #ad7be7);
      }
      /*&.btn05:hover .top-link-btn-icon {
        background-image: set-svg($link-05, #fff);
      }*/
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -140%;
        width: 120%;
        height: 100%;
        transform: skewX(-10deg);
        z-index: 2;
      }
      @include hover() {
        &.btn01::before {
          background-color: #ce7272;
        }
        &.btn02:before {
          background-color: #689e88;
        }
        &.btn03:before {
          background-color: #5694ae;
        }
        &.btn04:before {
          background-color: #bab767;
        }
        &.btn05:before {
          background-color: #ad7be7;
        }
      }
      /*&.btn01:hover {
        &::before {
          background-color: #ce7272;
        }
      }
      &.btn02:hover {
        &::before {
          background-color: #689e88;
        }
      }
      &.btn03:hover {
        &::before {
          background-color: #5694ae;
        }
      }
      &.btn04:hover {
        &::before {
          background-color: #bab767;
        }
      }
      &.btn05:hover {
        &::before {
          background-color: #ad7be7;
        }
      }*/
      @include hover() {
        &::before {
          animation: skewanime .5s forwards;
        }
      }
      /*&:hover::before {
        animation: skewanime .5s forwards;
      }*/
      @keyframes skewanime {
      100% {
        left:-10%;
        }
      }
      span, &::after {
        z-index: 3;
      }
    .top-link-btn-title {
      color: #000;
      margin-bottom: .3rem;
    }
      .top-link-btn-eng {
      color: #808080;
      font-family: Times New Roman;
      font-size: 0.7rem;
    }
      .top-link-btn-title {
        color: var(--gray-dark);
      }
      @include hover() {
        .top-link-btn-title {
          color: var(--white);
        }
        .top-link-btn-eng {
          color: var(--white);
        }
      }
      /*&:hover .top-link-btn-title {
        color: var(--white);
      }
      &:hover .top-link-btn-eng {
        color: var(--white);
      }*/
    }
  }
  @container (min-width: 960px) {
    .top-link-item{
      width: calc(19.2% - 0.5rem);
      &:nth-child(1) {
        width: calc(20.7% - 0.5rem);
      }
    }
  }
}


/* 重要なお知らせ */
.caution-container {
  display: flex;
  flex-direction: column;
  .caution-title {
   text-align: center;
    background-color: #e90000;
    color: $white;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.8rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .caution-news-list {
    background-color: #ffebeb;
    padding: 1.5rem 2rem;
    display: table;
    @container (min-width: 730px) {
      padding: 1.5rem 2.5rem;
    }
      .caution-news-container {
        margin-top: 1.2rem;
        &:nth-child(1) {
          margin-top: 0;
        }
        .date{
          padding-bottom: 0.4rem;
          display: flex;
          align-items: center;
          &::before {
            content: "";
            display: block;
            height: 1rem;
            width: 1rem;
            margin-right: .2rem;
            background-image: url("../../../images/top/imnews_mark.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
        .category {
          display: none;
        }
        .title {
          display: inline;
          a {
            //text-decoration: underline;
            color: #333;
            @include hover(){
              color: $gray-500;
            }
          }
          a[target="_blank"]:not(a[href$=".pdf"]){
            display: inline-flex;
            align-items: center;
            position: relative;
            &::after {
              content: '';
              background-image: set-svg($ext, #0e67b5);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              display: inline-block;
              width: 1.125rem;
              height: 1.125rem;
              min-width: 1.25rem;
              position: relative;
              margin-left: 0.5rem;
              transition: opacity $base-transition;
            }
            &:hover::after{
              //opacity: 0.5;
            }
          }
          a[href$=".pdf"] {
            display: inline-flex;
            align-items: center;
            position: relative;
            &::after {
              content: '';
              background-image: set-svg($pdf, #b0120a);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              display: inline-block;
              width: 1.375rem;
              height: 1.375rem;
              min-width: 1.375rem;
              position: relative;
              margin-left: 0.5rem;
              transition: opacity $base-transition;
            }
          }
        }
      }
      @container (min-width: 730px) {
        .caution-news-container {
          display: table-row;
          margin-top: 0;
        .date, .title {
          display: table-cell;
          padding-top: .6rem;
          padding-bottom: .6rem;
         }
         .date {
            display: flex;
            width: 115px;
            padding-right: 0.9rem;
         }
          .title {
            width: 100%;
          }
        }
      }
      @container (min-width: 922px) {
        .caution-news-container {
          .date {
            width: 130px;
          }
        }
      }
  }
}
@container (min-width: 620px) {
  .caution-container {
    flex-direction: row;
    .caution-title {
      width: 20%;
      padding-top: 1.8rem;
    }
    .caution-news-list {
      width: 80%;
    }
  }
}


/* 受付時間  */
.time-container {
  display: flex;
  flex-direction: column;
  .time-title {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    background-color: #3986CE;
    color: var(--white);
    //border: 2px solid #3986CE;
    padding: .8rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: .8rem;
      padding-left: 1rem;
    }
    &::before {
        content: "";
        display: inline-block;
        height: 3rem;
        width: 3rem;
        margin-right: 1rem;
        background-image: url("../../../images/top/time-icon.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
  }
  .time-text {
    border: 2px solid #3986CE;
    background-color: $white;
    padding: 1rem;
    .time-table {
      display: table;
      background: $white;
      width: 100%;
      padding-bottom: 1rem;
      border-bottom: solid 1px #e1e1e1;
      .time-row {
        padding-top: .6rem;
        .date {
          font-weight: 600;
          color: #3986CE;
        }
        .time {
          display: inline;
        }
        .tyu {
          display: block;
          @container (min-width: 500px) {
            display: inline;
            padding-left: 1rem;
          }
        }
      }
      .time-row:nth-child(1) {
        padding-top: 0;
      }
    }
    .time-desc {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: 1rem;
      text-align: center;
      p {
        width: 100%;
        span {
          color: #fb3d3d;
        }
      }
      div {
        width: calc(50% - 0.5rem);
      }
    }
    @container (min-width: 1150px) {
      .time-desc {
        justify-content: space-between;
        text-align: left;
        margin-top: 2rem;
        p {
          width: 40%;
        }
        div {
          width: calc(30% - 1.01rem);
          a {
            padding: .6rem 1rem;
          }
        }
      }
    }
  }
}

@container (min-width: 800px) {
  .time-container {
    flex-direction: row;
    .time-title {
      width: 30%;
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &::before {
        height: 7rem;
        width: 7rem;
        margin-right: initial;
        margin-bottom: .8rem;
      }
      span {
        padding-left: initial;
        margin-top: 1rem;
        font-size: .9rem;
      }
    }
    .time-text {
      width: 80%;
      padding: 2rem;
      .time-table {
        background-color: #FAFAFA;
        padding-bottom: 0rem;
        border-bottom: none;
        padding: 1rem;
        .time-row {
          display: table-row;
          &:nth-child(1) div {
            padding-bottom: .7rem;
            border-bottom: solid 1px #e1e1e1;
          }
          &:nth-child(2) div {
            padding-top: .7rem;
          }
        .date, .time, .tyu {
          display: table-cell;
         }
         .date, .time {
            width: 0;
            white-space: nowrap;
         }
         .date {
           padding-right: 1.75rem;
         }
         .time {
           padding-right: 1rem;
         }
         .tyu {
           padding-left: initial;
         }
        }
      }
    }
  }
}


/* 当院をご利用の方へ */
.top-heading-eng {
  color: var(--base);
  font-family: Times New Roman;
  font-size: .8rem;
  margin-bottom: .7rem;
}
.guide-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
  .top-guide-item {
    width: 100%;
    .top-guide-btn {
      display: block;
      padding: 1.7rem 2rem 1.7rem 1rem;
      border: solid 2px var(--base);
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.1);
      height: 100%;
      // hover アニメーション
      transition: ease .2s;
      overflow: hidden;
      .top-guide-btn-icon {
        height: 3rem;
        width: 3rem;
        margin-right: 1rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        &.btn01 {
          background-image: set-svg($guide-01, #1d3e87);
          @container (min-width: 600px) {
            height: 4.7rem;
            width: 4.7rem;
          }
        }
        &.btn02 {
          background-image: set-svg($guide-02, #1d3e87);
          @container (min-width: 600px) {
            height: 4.7rem;
            width: 4.7rem;
          }
        }
        &.btn03 {
          background-image: set-svg($guide-03, #1d3e87);
        }
        &.btn04 {
          background-image: set-svg($guide-04, #1d3e87);
        }
        &.btn05 {
          background-image: set-svg($guide-05, #1d3e87);
        }
      }
        @include hover() {
            .top-guide-btn-icon.btn01 {
              background-image: set-svg($guide-01, #fff);
            }
            .top-guide-btn-icon.btn02 {
              background-image: set-svg($guide-02, #fff);
            }
            .top-guide-btn-icon.btn03 {
              background-image: set-svg($guide-03, #fff);
            }
            .top-guide-btn-icon.btn04 {
              background-image: set-svg($guide-04, #fff);
            }
            .top-guide-btn-icon.btn05 {
              background-image: set-svg($guide-05, #fff);
            }
        }
      /*&:hover {
        .top-guide-btn-icon {
          &.btn01 {
            background-image: set-svg($guide-01, #fff);
          }
          &.btn02 {
            background-image: set-svg($guide-02, #fff);
          }
          &.btn03 {
            background-image: set-svg($guide-03, #fff);
          }
          &.btn04 {
            background-image: set-svg($guide-04, #fff);
          }
          &.btn05 {
            background-image: set-svg($guide-05, #fff);
          }
        }
      }*/
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -130%;
        width: 120%;
        height: 100%;
        transform: skewX(-25deg);
        z-index: 2;
        background-color: aqua;
      }
      @include hover() {
        &::before {
          animation: skewanime .5s forwards;
        }
      }
      /*&:hover::before {
        animation: skewanime .5s forwards;
      }*/
      @keyframes skewanime {
      100% {
        left:-10%;
        }
      }
      span {
        z-index: 3;
      }
      //hover アニメーション 白背景青字のボタン
      @include hover() {
        color: var(--base);
      }
      @include hover() {
        &::before {
          background-color: var(--base);
        }
      }
      /*&:hover {
        color: var(--base);
      }
      &::before {
      background-color: var(--base);
      }*/
      // Icon
      @include hover() {
        &::after {
        margin-right: -.3rem;
        background-image: set-svg($chev, $white);
        }
      }
      /*&:hover::after {
        margin-right: -.3rem;
        background-image: set-svg($chev, $white);
      }*/
      &::after {
        content: '';
        background-image: set-svg($chev, #1d3e87);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
        z-index: 3;
      }
      .top-guide-btn-title {
        color: var(--base);
        &.lg {
          @container (min-width: 600px) {
            font-size: 1.2rem;
          }
        }
      }
      @include hover() {
        .top-guide-btn-title {
          color: $white;
        }
      }
      /*&:hover .top-guide-btn-title {
        color: $white;
      }*/
    }
  }
  @container (min-width: 760px) {
    .top-guide-item {
      width: calc(33.333333% - 0.666666rem);
      &:nth-child(1), &:nth-child(2) {
        width: calc(50% - 0.5rem);
      }
      .top-guide-btn {
         padding: 2rem 4.4rem 2rem 2.2rem;
      }
    }
  }
}


/* バナー */
.top-banner-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
}
.scrollbtn-inner{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
}

.scrollbtn-container{
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 100%;
  @include media(lg) {
    padding: 0;
  }
}

.scrollbtn-upper-content{
  position: relative;
  z-index: 1;
  margin-bottom: 2rem;
  @include media(lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .upper-heading{
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1rem;
    white-space: nowrap;
    @include media(lg) {
      font-size: 2.25rem;
      margin-bottom: 0;
    }
    .en{
      font-family: hind;
      color: $uno;
      font-size: 1rem;
      display: inline-block;
      margin-left: 1.142857rem;
      margin-top: 0.5em;
      @include media(lg) {
        margin-left: 2rem;
      }
    }
  }
  .upper-desc{
    @include media(lg) {
      padding: 0 1rem 0 2rem;
      flex-grow: 1;
    }
  }
}

.scrollbtn-slide {
  width: 100%;
  height: 100%;
}

.scrollbtn-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 208px !important;
  height: 117px !important;
  //height: 100%;
  //width: 100%;
  margin-right: 1rem;
  @include media(lg) {
    width: 390px !important;
    height: 216px !important;
  }
  &:last-child{
    margin-right: 0;
  }
}

.scrollbtn-link{
  font-size: 1rem;
  background-color: var(--gray-700);
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  position: relative;
  text-align: center;
  padding: 3.25rem 1rem;
  line-height: 1.4;
  overflow: hidden;
  transition: opacity $base-transition;
  @include media(lg) {
    font-size: 1.125rem;
  }
  span{
    position: relative;
    z-index: 1;
  }
  &:hover{
    color: $base-text-color;
    cursor: pointer;
  }
  /*&::before{
    content: '';
    width: 6rem;
    height: 6rem;
    background: #EDF9FF;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -3rem;
    transform: translateY(-50%);
  }
  &::after{
    content: '';
    background-image: set-svg($chev, $uno);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 1.25rem;
    height: 1.25rem;
  }*/
}

.scrollbtn-tools{
  display: none;
  @include media(lg) {
    display: flex;
    align-items: center;
    //margin-top: 2rem;
  }
  /*.swiper-button-next,
  .swiper-button-prev,
  .swiper-scrollbar,
  .swiper-scrollbar-horizontal {
    position: static !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    margin: 0 !important;
  }*/
  
  .swiper-button-next,
  .swiper-button-prev{
    width: 2.75rem;
  }
  .swiper-button-next {
    right: 19px !important;
  }
  .swiper-button-prev {
    left: 19px !important;
  }
  .swiper-button-next::after{
    content: '';
    background-image: set-svg($chev, #fff);
    background-size: contain;
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
    display: inline-block;
  }
  .swiper-button-prev::after{
    content: '';
    background-image: set-svg($chev, #fff);
    background-size: contain;
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
    display: inline-block;
    transform: rotate(180deg);
  }
  .swiper-scrollbar {
    border-radius: 0;
    background: rgba($white,0.5);
    &.swiper-scrollbar-horizontal {
      height: 3px;
      margin-left: 1rem !important;
    }
  }
  .swiper-scrollbar-drag {
    background: $uno;
    border-radius: 0;
  }
}
.split-hero-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 7rem;
  @include media(lg) {
    flex-direction: row;
  }
}

.split-hero-content{
  width: 100%;
  height: 40vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 1rem;
  @include media(lg) {
    width: 50%;
    height: 40vh;
  }
  & + .split-hero-content{
    margin-top: 10rem;
    @include media(lg) {
      margin-top: 0;
    }
  }
  .panel-item{
    padding: 1.785714rem 1.5rem;
    border-radius: 0.5rem;
    background: $white;
    margin-bottom: -7rem;
    background: #fff;
    box-shadow: $base-box-shadow;
    width: 100%;
    @include media(lg) {
      width: 90%;
      padding: 2rem;
    }
    @include media(xl) {
      width: 80%;
    }
  }
  .panel-heading{
    font-size: 1.571428rem;
    font-weight: 400;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: 1.785714rem;
    @include media(lg) {
      font-size: 1.75rem;
      margin-bottom: 2rem;
    }
    &:after{
      content: '';
      width: 3rem;
      height: 2px;
      background: #595959;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
    &.base{
      color: $base;
      &:after{
        background: $base;
      }
    }
    &.uno{
      color: $uno;
      &:after{
        background: $uno;
      }
    }
  }
}

.top-bnr-wrapper{
  padding: 4.5rem 0;
  position: relative;
  @include media(lg) {
    padding: 7.75rem 0;
  }
  &::before{
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('/images/top/top-bnr-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 0;
    opacity: 0.4;
  }
  &::after{
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #92c4ef, #d6f1ff);
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 1;
    mix-blend-mode: multiply;
    opacity: 0.8;
  }
  & > *{
    position: relative;
    z-index: 2;
  }
}

.top-bnr-link{
  display: inline-block;
  background: #fff;
  border-radius: 0.5em;
  img{
    transition: opacity $base-transition;
  }
  @media (hover: hover) {
    &:hover img{
      opacity: 0.7;
    }
  }
}

.bnr-list{
  padding: 2rem 0;
  @include media(lg) {
    padding: 4.5rem 0;
  }
  img{
    transition: opacity $base-transition;
  }
}