@charset 'UTF-8';

//　TOPリンクボタン
$link-01:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill=""><path class="st0" d="M44.7,13.7h4.6V16h-4.6V13.7z M49.3,42.4h-1.1V18.3h-3.4v2.3h1.1v21.8h-3.4V28.6h-9.2v13.8H9.2V20.6h17.2v-2.3H6.9v24.1H5.7c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h43.5c0.6,0,1.1-0.5,1.1-1.1C50.4,42.9,49.9,42.4,49.3,42.4z M40.1,42.4h-4.6V30.9h4.6V42.4z M29.8,36.7v-8H12.6v8h-1.1V39h19.5v-2.3H29.8z M27.5,36.7H14.9v-5.7h12.6V36.7z M5.7,13.7V16h20.6v-2.3H5.7zM39,17.2h-2.3v-2.3h-2.3v2.3h-2.3v2.3h2.3v2.3h2.3v-2.3H39V17.2z M40.1,11.5h-9.2c-1.3,0-2.3,1-2.3,2.3v9.2c0,1.3,1,2.3,2.3,2.3h9.2c1.3,0,2.3-1,2.3-2.3v-9.2C42.4,12.5,41.4,11.5,40.1,11.5z M40.1,22.9h-9.2v-9.2h9.2V22.9z"/></svg>') !default;

$link-02:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill=""><path d="M35.5,32.1c-0.6-0.1-2.3,2.3-2.3,2.3h-3.8c-1.3,0-1.8,0-2.4,0c-0.4,0-0.9,0-1.7,0h-3.5c0,0-1.7-2.4-2.3-2.3c-6.2,1.4-12.6,5.5-12.6,9.8v6.2h41.2v-6.2C48.1,37.6,41.7,33.5,35.5,32.1z M45.8,45.8H9.2v-3.9c0-1,0.8-2.5,3-4.1c1.9-1.4,4.3-2.5,6.8-3.2c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.5,0.6,0.7c0.1,0.1,0.1,0.2,0.2,0.2l0,0.1l0,0l0.7,1h4.7c0.8,0,1.3,0,1.7,0c0.7,0,1.2,0,2.4,0h5l0.7-1l0,0l0,0l0-0.1c0-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.5,0.6-0.7c0,0,0.1-0.1,0.1-0.1c2.5,0.7,5,1.8,6.8,3.2c2.2,1.6,3,3.1,3,4.1V45.8z"/><path d="M36.7,43.5v-6.9H39v6.9H36.7z"/><path d="M41.2,41.2h-6.9V39h6.9V41.2z"/><path d="M27.5,6.9C21.2,6.9,16,12,16,18.3c0,6.3,5.1,11.5,11.5,11.5S39,24.7,39,18.3C39,12,33.8,6.9,27.5,6.9zM27.5,27.5c-5.1,0-9.2-4.1-9.2-9.2c0-5.1,4.1-9.2,9.2-9.2c5.1,0,9.2,4.1,9.2,9.2C36.7,23.4,32.6,27.5,27.5,27.5z"/></svg>') !default;

$link-03:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="">	<path class="st0" d="M27.5,6.9c-11.4,0-20.6,9.2-20.6,20.6c0,4,1.1,7.8,3.1,10.9l1.2-2.5c-1.3-2.5-2-5.4-2-8.4		c0-10.1,8.2-18.3,18.3-18.3s18.3,8.2,18.3,18.3c0,3-0.7,5.9-2,8.4l1.2,2.5c2-3.2,3.1-6.9,3.1-10.9C48.1,16.1,38.9,6.9,27.5,6.9z"/>	<path class="st0" d="M27.5,19.5c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C33.2,22,30.7,19.5,27.5,19.5z		 M27.5,28.6c-1.9,0-3.4-1.5-3.4-3.4s1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4S29.4,28.6,27.5,28.6z"/>	<path class="st0" d="M38.9,28.9c0-0.1-0.1-0.2-0.2-0.3c0.1-0.7,0.2-1.5,0.2-2.3c0-6.3-5.1-11.5-11.5-11.5S16,20,16,26.4		c0,0.8,0.1,1.5,0.2,2.3c-0.1,0.1-0.1,0.2-0.2,0.3l-5.4,12.8c-1.3,3,0.9,6.4,4.2,6.4h3.9c0,0,0.1,0,0.1,0h17c0,0,0.1,0,0.1,0h3.9		c3.3,0,5.5-3.3,4.2-6.4L38.9,28.9z M36.8,45.7c-0.2,0.1-0.4,0.1-0.6,0.1h-0.1c-0.4,0-0.8-0.2-1.1-0.4c0,0-0.1-0.1-0.1-0.1l-2.2-5.6		c-0.2-0.6-0.9-0.9-1.5-0.6c-0.6,0.2-0.9,0.9-0.6,1.5l2.1,5.3H22.4l2.1-5.3c0.2-0.6-0.1-1.3-0.6-1.5c-0.6-0.2-1.3,0.1-1.5,0.6		l-2.2,5.6c0,0-0.1,0.1-0.1,0.1c-0.3,0.3-0.7,0.4-1.1,0.4h-0.1c-0.2,0-0.4,0-0.6-0.1c-0.9-0.4-1.3-1.4-0.9-2.3l2.8-6.6		c0.6-1.5,2.1-2.4,3.7-2.4h7.3c1.6,0,3.1,1,3.7,2.4l2.8,6.6C38,44.3,37.6,45.3,36.8,45.7z M40.1,45.8h-0.4c0.5-1,0.5-2.2,0.1-3.3		l-2.8-6.6c-1-2.3-3.3-3.8-5.8-3.8h-7.3c-2.5,0-4.8,1.5-5.8,3.8l-2.8,6.6c-0.5,1.1-0.4,2.3,0.1,3.3h-0.4c-1.6,0-2.7-1.7-2.1-3.2		l5.4-12.8c0.4-0.8,1.2-1.4,2.1-1.4h0.2c-0.3-0.7-0.4-1.5-0.5-2.3c-0.6,0-1.2,0.2-1.7,0.4c0-0.1,0-0.2,0-0.2c0-5.1,4.1-9.2,9.2-9.2		s9.2,4.1,9.2,9.2c0,0.1,0,0.2,0,0.2c-0.5-0.3-1.1-0.4-1.7-0.4c0,0.8-0.2,1.6-0.5,2.3h0.2c0.9,0,1.8,0.6,2.1,1.4l5.4,12.8		C42.8,44.2,41.7,45.8,40.1,45.8z"/>	<path class="st0" d="M32.7,14.3l0.9-2.1c-1.9-0.8-4-1.2-6.1-1.3c-2.1,0-4.2,0.4-6.1,1.2l0.9,2.1c1.7-0.7,3.5-1,5.3-1		S31.1,13.6,32.7,14.3z"/></svg>') !default;

$link-04:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill=""><path class="st0" d="M26.8,47.9c0.4,0.3,0.9,0.3,1.3,0c0,0,0,0-0.7-0.9C26.8,47.9,26.8,47.9,26.8,47.9z"/>			<path class="st0" d="M38.8,11.6c-3-3-7.1-4.7-11.3-4.7c-4.3,0-8.3,1.7-11.3,4.7c-3,3-4.7,7.1-4.7,11.4c0,7.2,3.9,13.4,7.7,17.8				c1.9,2.2,3.8,4,5.2,5.2c0.7,0.6,1.3,1.1,1.7,1.4c0.2,0.2,0.4,0.3,0.5,0.4c0.1,0,0.1,0.1,0.1,0.1l0,0l0,0l0,0l0.7-0.9l0.7,0.9l0,0				l0,0l0,0c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.2,0.5-0.4c0.4-0.3,1-0.8,1.7-1.4c1.4-1.2,3.3-3,5.2-5.2c3.8-4.4,7.7-10.6,7.7-17.8				C43.5,18.7,41.9,14.6,38.8,11.6z M34.1,39.3c-1.8,2.1-3.6,3.8-5,5c-0.6,0.6-1.2,1-1.6,1.3c-0.4-0.3-0.9-0.7-1.6-1.3				c-1.4-1.2-3.2-2.9-5-5c-3.7-4.2-7.2-10-7.2-16.3c0-3.7,1.5-7.2,4-9.8c2.6-2.6,6.1-4.1,9.7-4.1c3.6,0,7.1,1.5,9.7,4.1				c2.6,2.6,4,6.1,4,9.8C41.2,29.3,37.7,35.1,34.1,39.3z"/>			<path class="st0" d="M27.5,16c-3.8,0-6.9,3.1-6.9,6.9c0,3.8,3.1,6.9,6.9,6.9c3.8,0,6.9-3.1,6.9-6.9C34.4,19.1,31.3,16,27.5,16z				 M27.5,27.5c-2.5,0-4.6-2.1-4.6-4.6c0-2.5,2.1-4.6,4.6-4.6c2.5,0,4.6,2.1,4.6,4.6C32.1,25.4,30,27.5,27.5,27.5z"/></svg>') !default;

$link-05:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill=""><path class="st0" d="M27.5,13.8c-4.4,0-8,3.8-8,8.6c0,4.7,3.6,8.6,8,8.6s8-3.8,8-8.6C35.5,17.6,31.9,13.8,27.5,13.8z M27.5,28.8c-3.3,0-6-2.9-6-6.4c0-3.6,2.7-6.4,6-6.4s6,2.9,6,6.4C33.5,25.9,30.8,28.8,27.5,28.8z M49.3,12.5c0-3.7-3-6.7-6.7-6.7c-2.4,0-4.6,1.3-5.7,3.2c-2.8-1.4-5.9-2.2-9.3-2.2C16.1,6.8,6.9,16,6.9,27.4c0,11.4,9.3,20.7,20.7,20.7c11.4,0,20.7-9.3,20.7-20.7c0-3.3-0.8-6.5-2.2-9.3C48,17,49.3,14.9,49.3,12.5z M38.9,11.7h2.9V8.8h1.4v2.9h2.9v1.4h-2.9v2.9h-1.4v-2.9h-2.9V11.7z M38.5,42.1c-0.3,0.4-0.6,0.8-0.9,1.1c-2.9,1.8-6.3,2.9-10,2.9c-3.8,0-7.2-1.1-10.2-3c-0.2-0.3-0.5-0.6-0.8-1c-0.3-0.4-0.5-0.9-0.5-1.3c0-1.1,0.8-2,1.9-2.2c7.1-1,12-0.9,19,0c1.1,0.1,1.8,1.1,1.8,2.1C38.9,41.2,38.8,41.7,38.5,42.1z M46.2,27.4c0,5-2,9.5-5.2,12.9c-0.2-1.9-1.6-3.6-3.6-3.8c-7.3-1-12.3-1.1-19.6,0c-2,0.3-3.4,1.9-3.6,3.9c-3.2-3.3-5.2-7.9-5.2-12.9c0-10.3,8.3-18.6,18.6-18.6c3,0,5.9,0.7,8.5,2c-0.1,0.5-0.2,1-0.2,1.6c0,3.7,3,6.7,6.7,6.7c0.5,0,1.1-0.1,1.6-0.2C45.4,21.5,46.2,24.4,46.2,27.4z"/></svg>') !default;


//　TOP当院をご利用の方へ
$guide-01:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill=""><path d="M39.6,32.3L39.6,32.3c2.3,0,4.2-1.8,4.2-4.1c0-1.5-0.8-2.9-2.1-3.6V11.5c0-1.2-0.9-2.1-2.1-2.1h-6.2c-0.6,0-1-0.5-1-1V6.2h-2.1v2.1c0,1.4,0.9,2.5,2.1,2.9v1.2h-1c-0.6,0-1,0.5-1,1v6.2c0,0.6,0.5,1,1,1h4.2c0.6,0,1-0.5,1-1v-6.2c0-0.6-0.5-1-1-1h-1v-1h5.2V24H20.9c-0.1,0-0.2,0-0.2-0.1l-0.4-0.4l0.4-0.4c1.5-1.5,1.5-4,0-5.6l-1.9-1.9c-1.5-1.5-4-1.5-5.6,0l-0.7,0.7c-1.4-0.4-3,0-4.1,1.1c-1.6,1.6-1.6,4.3,0,5.9l0.9,0.9v11.1H6.2v2.1h3.2c-0.7,0.7-1.1,1.6-1.1,2.6c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6c0-1-0.4-1.9-1.1-2.6h20.9c-0.7,0.7-1.1,1.6-1.1,2.6c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6c0-1-0.4-1.9-1.1-2.6h3.2v-2.1h-4.2V32.3z M34.4,18.8h-2.1v-4.2h2.1V18.8z M14.8,17.2c0.7-0.7,1.9-0.7,2.6,0l1.9,1.9c0.7,0.7,0.7,1.9,0,2.6l-0.4,0.4l-4.5-4.5L14.8,17.2z M10,19c0.8-0.8,2.1-0.8,2.9,0l6.4,6.4c0.4,0.5,1.1,0.7,1.7,0.7h18.7c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H18.2L10,22C9.2,21.2,9.2,19.8,10,19z M12,41.7c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6C13.5,41,12.8,41.7,12,41.7z M11.5,35.4v-9l5.5,5.5c0.2,0.2,0.5,0.3,0.8,0.3h19.7v3.1H11.5z M38,41.7c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6C39.6,41,38.9,41.7,38,41.7z"/></svg>') !default;

$guide-02:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill=""><path d="M38.1,17.1l-5.2-8.3c-0.5-0.8-1.2-1.2-2-1.4c-0.8-1.9-2.6-3.2-4.8-3.2c-2.9,0-5.2,2.3-5.2,5.2c0,2.9,2.3,5.2,5.2,5.2c1,0,1.9-0.3,2.6-0.7l1.1,1.8h-10c0,0-0.1,0-0.1,0c-2.4,0.1-4.5,1-5.9,2.6c-1.5,1.6-2.2,3.7-2.3,5.7c0,2,0.7,4.1,2.2,5.7c1.3,1.4,3,2.3,5.1,2.5v0.1l0,0v0v10.4c0,1.7,1.3,3,3,3.1c1.7,0.1,3.1-1.2,3.3-2.8l0.8-7.6h0.6l0.8,7.6c0.2,1.7,1.6,2.9,3.3,2.8c1.7-0.1,3-1.5,3-3.1v-8.3l0,0V21.9h2.1c1.1,0,2.2-0.6,2.7-1.6C38.7,19.3,38.7,18.1,38.1,17.1z M28.6,7.8c-1.5,0.9-1.9,2.8-1,4.3l0,0c-0.5,0.3-1,0.4-1.5,0.4c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1c1.1,0,2.1,0.6,2.6,1.4C28.6,7.7,28.6,7.7,28.6,7.8z M36.3,19.3c-0.2,0.3-0.5,0.5-0.9,0.5h-3.1c-0.6,0-1,0.5-1,1v13.5l0,0v8.3c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1.1-0.9l-0.9-8.5c-0.1-0.5-0.5-0.9-1-0.9h-2.5c-0.5,0-1,0.4-1,0.9l-0.9,8.5c-0.1,0.6-0.5,1-1.1,0.9c-0.6,0-1-0.5-1-1V32.3l0.1,0c0.3,0,0.6,0,0.9,0c0.6,0,1.3,0,1.9-0.2c0.6-0.1,1.2-0.4,1.6-1c0.4-0.6,0.6-1.3,0.6-2c0-0.8-0.2-1.5-0.7-2.1c-0.5-0.5-1.1-0.8-1.7-0.9C23,26.1,22.3,26,21.7,26c-0.3,0-0.6,0-0.9,0v-5.2c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-1.4,0.1-2.3,0.6-3,1.3c-0.7,0.8-1.1,1.8-1.1,2.9c0,1.1,0.4,2.1,1.1,2.9c0.6,0.6,1.5,1.1,2.7,1.3c0.1,0,0.2,0.1,0.4,0.1c0.3,0,0.7,0,1,0l0,0c0.3,0,0.6,0,0.9,0c0.6,0,1.1,0,1.5,0.1c0.4,0.1,0.5,0.2,0.6,0.3c0.1,0.1,0.2,0.2,0.2,0.7c0,0.5-0.1,0.7-0.2,0.7c0,0.1-0.1,0.2-0.5,0.2c-0.3,0.1-0.8,0.1-1.4,0.1c-0.3,0-0.6,0-0.9,0l-0.1,0c-0.3,0-0.6,0-0.9,0c-0.1,0-0.2,0-0.2,0c-2-0.1-3.6-0.8-4.6-2c-1.1-1.2-1.6-2.8-1.6-4.3c0-1.5,0.6-3.1,1.7-4.3c1.1-1.1,2.6-1.9,4.6-1.9c0,0,0,0,0.1,0h11.8c0.4,0,0.7-0.2,0.9-0.5c0.2-0.3,0.2-0.7,0-1.1L29.3,11c-0.3-0.5-0.2-1.1,0.3-1.4c0.5-0.3,1.1-0.2,1.4,0.3l5.2,8.3C36.5,18.5,36.5,18.9,36.3,19.3z M18.8,22.2v3.6c-0.2-0.1-0.4-0.3-0.5-0.4c-0.3-0.3-0.5-0.8-0.5-1.4c0-0.6,0.2-1.1,0.6-1.5C18.4,22.4,18.5,22.3,18.8,22.2z"/></svg>') !default;

$guide-03:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill=""><path d="M42.9,41.2l-2.8-2.8c0.2-0.8,0-1.7-0.6-2.3l-1.2-1.2c0.8-2.2,0.4-4.7-1.3-6.5L22.4,13.9l1.7-1.7c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7,0L19,13.9l-4.3-4.3l0.9-0.9c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7,0l-6.8,6.8c-0.5,0.5-0.5,1.2,0,1.7c0.5,0.5,1.2,0.5,1.7,0l0.9-0.9l4.3,4.3l-3.4,3.4c-0.5,0.5-0.5,1.2,0,1.7c0.5,0.5,1.2,0.5,1.7,0l1.7-1.7l14.5,14.5c1.7,1.7,4.3,2.2,6.5,1.4l1.2,1.2c0.6,0.6,1.5,0.8,2.3,0.6l2.8,2.8c0.5,0.5,1.2,0.5,1.7,0C43.4,42.4,43.4,41.7,42.9,41.2z M11.1,13.3l2.2-2.2l4.3,4.3l-2.2,2.2L11.1,13.3z M15.4,21l5.6-5.6l4.6,4.6L20,25.6L15.4,21zM36.3,34.1l-2.2,2.2c-1.4,0.6-3.1,0.3-4.2-0.9l-0.5-0.5l1.9-1.9c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7,0l-1.9,1.9l-1.7-1.7l1.9-1.9c0.5-0.5,0.5-1.2,0-1.7s-1.2-0.5-1.7,0l-1.9,1.9l-2.6-2.6l5.6-5.6l8.2,8.2C36.6,31,36.9,32.7,36.3,34.1z M37.8,37.8c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0l-0.9-0.9c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2l0.9,0.9c0,0,0,0,0,0C38,37.6,37.9,37.7,37.8,37.8z"/></svg>') !default;

$guide-04:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill=""><path class="st0" d="M26,30.2h5.2v2.1H26v5.2H24v-5.2h-5.2v-2.1H24V25H26V30.2z M33.3,18.8h-1v-2.1c0-1.2-0.9-2.1-2.1-2.1H26V8.3h8.3V6.2H16.7c-1.4,0-2.7,0.8-3.6,1.6c-0.9,0.8-1.6,1.8-2,2.5l1.8,1c0.3-0.5,0.9-1.4,1.6-2c0.8-0.7,1.5-1.1,2.2-1.1H24v6.2h-4.2c-1.2,0-2.1,0.9-2.1,2.1v2.1h-1c-3.5,0-6.2,2.8-6.2,6.2v15.6c0,1.7,1.4,3.1,3.1,3.1h22.9c1.7,0,3.1-1.4,3.1-3.1V25C39.6,21.5,36.8,18.8,33.3,18.8z M19.8,16.7h10.4v2.1H19.8V16.7z M37.5,40.6c0,0.6-0.5,1-1,1H13.5c-0.6,0-1-0.5-1-1V25c0-2.3,1.9-4.2,4.2-4.2h16.7c2.3,0,4.2,1.9,4.2,4.2V40.6z"/></svg>') !default;

$guide-05:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill=""><path d="M12.5,10.4c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-0.8,0-1.4,0.4-1.8,1h-0.3c-1.7,0-3.1,1.4-3.1,3.1v9.4h2.1v-9.4c0-0.6,0.5-1,1-1h0.3C11.1,10,11.7,10.4,12.5,10.4z"/><path d="M10.6,19.8H9.4H7.3c-0.6,0-1,0.5-1,1c0.2,2.7,1.4,5.2,3.3,7.1c1.4,1.4,3.1,2.4,5,2.9v2.5c0,1.4,0.9,2.5,2.1,2.9c0,4.1,3.4,7.5,7.6,7.5c4.2,0,7.6-3.4,7.6-7.6V32c0-2.2,1.7-3.9,3.9-3.9c2.2,0,3.9,1.7,3.9,3.9v2.5c-1.2,0.4-2.1,1.6-2.1,2.9c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1c0-1.4-0.9-2.5-2.1-2.9V32c0-3.3-2.7-6-6-6c-3.3,0-6,2.7-6,6v4.2c0,3-2.4,5.5-5.5,5.5c-3,0-5.4-2.4-5.5-5.4c1.2-0.4,2.1-1.6,2.1-2.9v-2.5c1.9-0.5,3.6-1.5,5-2.9c1.9-1.9,3.1-4.4,3.3-7.1c0.1-0.6-0.4-1-1-1v-9.4c0-1.7-1.4-3.1-3.1-3.1h-0.3c-0.4-0.6-1-1-1.8-1c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0.8,0,1.4-0.4,1.8-1H25c0.6,0,1,0.5,1,1v9.4h-1.2c-0.6,0-1,0.5-1.1,1c-0.2,1.2-0.8,2.4-1.7,3.3c-1.1,1.1-2.7,1.8-4.3,1.8c-1.6,0-3.2-0.6-4.3-1.8c-0.9-0.9-1.5-2-1.7-3.3C11.6,20.3,11.2,19.8,10.6,19.8z M25.6,21.9h1.2c-0.4,1.7-1.3,3.3-2.5,4.5c-1.8,1.8-4.1,2.7-6.6,2.7c-2.5,0-4.9-1-6.6-2.7c-1.3-1.3-2.1-2.8-2.5-4.5h1.2c0.4,1.4,1.1,2.7,2.1,3.7c1.5,1.5,3.6,2.4,5.8,2.4c2.2,0,4.2-0.9,5.8-2.4C24.5,24.5,25.2,23.3,25.6,21.9z M40.6,36.5c0.6,0,1,0.5,1,1s-0.5,1-1,1s-1-0.5-1-1S40,36.5,40.6,36.5z M18.8,31.2v2.1c0,0.6-0.5,1-1,1s-1-0.5-1-1v-2.1H18.8z"/></svg>') !default;




/*$btn01: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill=""><path d="M44.7,13.8h4.6V16h-4.6V13.8z"/><path class="st1" d="M6.9,18.3h19.5v2.3H9.2v21.8h24.1V28.6h9.2v13.8h3.4V20.6h-1.1v-2.3h3.4v24.1h1.1c0.6,0,1.1,0.5,1.1,1.1	c0,0.6-0.5,1.1-1.1,1.1H5.7c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h1.1V18.3z M35.5,42.4h4.6V30.9h-4.6V42.4z"/><path class="st1" d="M30.9,36.7h-1.1v-8H12.6v8h-1.1V39h19.5V36.7z M14.9,30.9v5.7h12.6v-5.7H14.9z"/><path class="st0" d="M5.7,13.8V16h20.6v-2.3H5.7z"/><path class="st0" d="M39,17.2h-2.3v-2.3h-2.3v2.3h-2.3v2.3h2.3v2.3h2.3v-2.3H39V17.2z"/><path class="st1" d="M42.4,13.7c0-1.3-1-2.3-2.3-2.3h-9.2c-1.3,0-2.3,1-2.3,2.3v9.2c0,1.3,1,2.3,2.3,2.3h9.2c1.3,0,2.3-1,2.3-2.3V13.7z M30.9,13.7h9.2v9.2h-9.2V13.7z"/></svg>') !default;*/

$ext: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve" fill=""><path d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5l0,0v14c0,1.1,0.9,2,2,2l0,0h14c1.1,0,2-0.9,2-2v-7h-2V19zM14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/></svg>') !default;


$arrow: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve" fill=""><polygon points="12.9,4 10.7,6.1 15.1,10.4 3,10.4 3,13.6 15,13.6 10.7,17.9 12.9,20.1 21,12 "/></svg>') !default;

$ext: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve" fill=""><path d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5l0,0v14c0,1.1,0.9,2,2,2l0,0h14c1.1,0,2-0.9,2-2v-7h-2V19zM14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/></svg>') !default;

// 矢印（右）
$arrow-right: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M21.7,12.8C21.8,12.8,21.8,12.8,21.7,12.8c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1l-7.7-7.7c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l5.1,5.1H3.5C2.7,10.5,2,11.2,2,12s0.7,1.5,1.5,1.5h13.3l-5.1,5.1c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4l7.7-7.7C21.6,13,21.7,12.9,21.7,12.8z"/></svg>') !default;

// 矢印（上）
$arrow-up: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M4,12l1.4,1.4L11,7.8V20h2V7.8l5.6,5.6L20,12l-8-8L4,12z"/></svg>') !default;

// caret（下）
$caret-down: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><polygon points="2,7.6 3.2,6.4 12,15.2 20.8,6.4 22,7.6 12,17.6 "/></svg>') !default;

// Circle Plus
$circle-plus: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M17,13h-4v4c0,0.6-0.4,1-1,1s-1-0.4-1-1v-4H7c-0.6,0-1-0.4-1-1s0.4-1,1-1h4V7c0-0.6,0.4-1,1-1s1,0.4,1,1v4h4c0.6,0,1,0.4,1,1S17.6,13,17,13z"/></svg>') !default;

// Circle Minus
$circle-minus: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8s8,3.6,8,8C20,16.4,16.4,20,12,20z"/><path d="M17,11H7c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1S17.6,11,17,11z"/></svg>') !default;

// Burger
$burger: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M3,17h18c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,19H3c-0.6,0-1-0.4-1-1c0-0.5,0.4-0.9,0.9-1L3,17h18H3z M3,11l18,0c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,13L3,13c-0.6,0-1-0.4-1-1c0-0.5,0.4-0.9,0.9-1L3,11l18,0L3,11z M3,5h18c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,7H3C2.4,7,2,6.6,2,6c0-0.5,0.4-0.9,0.9-1L3,5h18H3z"/></svg>') !default;

// PDF
$pdf: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/><path d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/><path d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/><path d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/></svg>') !default;

// Excelファイル
$excel: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/><polygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/></svg>') !default;

// Wordファイル
$word: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/><polygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/></svg>') !default;

// Document
$document: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/></svg>') !default;

// 新規ウインドウ
$open-new-window: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/></svg>') !default;

// カレンダー
$calendar: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/></svg>') !default;

// Mail
$mail: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/></svg>') !default;

// Checkbox
$checkbox: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M6,3h12c1.7,0,3,1.3,3,3v12c0,1.7-1.3,3-3,3H6c-1.7,0-3-1.3-3-3V6C3,4.3,4.3,3,6,3zM6,5C5.4,5,5,5.4,5,6v12c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V6c0-0.6-0.4-1-1-1H6z"/></svg>') !default;

// Checkbox checked
$checkbox-checked: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M18,3c1.7,0,3,1.3,3,3v12c0,1.7-1.3,3-3,3H6c-1.7,0-3-1.3-3-3V6c0-1.7,1.3-3,3-3H18z M16.5,8L10,14.4L7.5,12c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l3,3c0.3,0.3,0.8,0.3,1.1,0l7-7c0.3-0.3,0.3-0.8,0-1.1C17.2,7.7,16.8,7.7,16.5,8z"/></svg>') !default;

// Radio
$radio: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,3.5c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5S16.7,3.5,12,3.5z"/></svg>') !default;

// Radio checked
$radio-checked: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,3.5c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5S16.7,3.5,12,3.5z M12,6c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S8.7,6,12,6z"/></svg>') !default;

// Remove
$remove: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17,13H7v-2h10V13z"/></svg>') !default;

$chev: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M11,16.2l-1.4-1.4l2.8-2.8 L9.6,9.2L11,7.8l4.2,4.2L11,16.2z"/></svg>') !default;

$download: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" fill=""><path d="M2,12h2v5h16v-5h2v5c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V12 M12,15l5.5-5.5l-1.4-1.4L13,11.2V2h-2v9.2L7.9,8.1L6.5,9.6L12,15z"/></svg>') !default;

$fax: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><path d="M19.9,8.6V5.7c0-0.3-0.1-0.6-0.3-0.8L18,3.3C17.7,3.1,17.5,3,17.2,3H8.6C8,3,7.5,3.5,7.5,4.1v15.8C7.5,20.5,8,21,8.6,21 h11.2c0.6,0,1.1-0.5,1.1-1.1V9.8C21,9.1,20.5,8.6,19.9,8.6z M13.1,18.2c0,0.3-0.3,0.6-0.6,0.6h-1.1c-0.3,0-0.6-0.3-0.6-0.6v-1.1 c0-0.3,0.3-0.6,0.6-0.6h1.1c0.3,0,0.6,0.3,0.6,0.6V18.2z M13.1,13.7c0,0.3-0.3,0.6-0.6,0.6h-1.1c-0.3,0-0.6-0.3-0.6-0.6v-1.1 c0-0.3,0.3-0.6,0.6-0.6h1.1c0.3,0,0.6,0.3,0.6,0.6V13.7z M17.6,18.2c0,0.3-0.3,0.6-0.6,0.6h-1.1c-0.3,0-0.6-0.3-0.6-0.6v-1.1 c0-0.3,0.3-0.6,0.6-0.6h1.1c0.3,0,0.6,0.3,0.6,0.6V18.2z M17.6,13.7c0,0.3-0.3,0.6-0.6,0.6h-1.1c-0.3,0-0.6-0.3-0.6-0.6v-1.1 c0-0.3,0.3-0.6,0.6-0.6h1.1c0.3,0,0.6,0.3,0.6,0.6V13.7z M17.6,9.8H9.8V5.2h5.6v1.7c0,0.3,0.3,0.6,0.6,0.6h1.7V9.8z M5.2,7.5H4.1 C3.5,7.5,3,8,3,8.6v11.2C3,20.5,3.5,21,4.1,21h1.1c0.6,0,1.1-0.5,1.1-1.1V8.6C6.4,8,5.9,7.5,5.2,7.5z"/></svg>') !default;

/*$top-arrow: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><ellipse class="st0" cx="13.1" cy="13.2" rx="12.5" ry="12.5"/><path class="st1" d="M10.8,8.8l4.7,4.7l-4.7,4.7"/></svg>') !default;*/