@charset 'UTF-8';
@use 'sass:math';
@use './color' as *;



// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;



// root font size
$root-font-size: (
  xs: 14px,
  sm: 14px,
  md: 14px,
  lg: 16px,
  xl: 16px
) !default;



$font-size-percentage: (
  'xsmall'  : 62.5%,
  'small'   : 75%,
  'medium'  : 87.5%,
  'large'   : 112.5%,
  'xlarge'  : 125%,
  'xxlarge' : 150%,
  'exlarge' : 200%
) !default;



// Base font family
$base-font-family: system-ui, -apple-system, Robot, 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Noto Sans CJK JP', 'メイリオ', Meiryo, Arial, sans-serif;

// Base text color
$base-text-color: $gray-dark !default;

// Base background color
$base-background-color: $white !default;

// Header background color
$header-background-color: $gray-200 !default;

// Footer background color
$footer-background-color: $gray-200 !default;

// Base font size
$base-font-size: 1rem !default;

// Base line height
$base-line-height: 1.6 !default;

// Spacer
$base-spacer: 1rem !default;

// Base gutter
$base-gutter: $base-spacer !default;

// Section content ( .section-content, .section-block )
$section-content-gutter: $base-spacer * 4 !default;

// Container side padding
$container-side-padding: $base-spacer !default;

// Container max width
$container-max-width: 900px !default;

// Burger
$collapse-class-name: '.collapse' !default;

// Accordion
$accordion-open-class-name: '.active' !default;

// Pagetop
$pagetop-active-class-name: '.pagetop-active';

// Pagination
$pagination-active-class: '.current';



// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ('<', '%3c'),
  ('>', '%3e'),
  ('#', '%23'),
  ('(', '%28'),
  (')', '%29'),
) !default;



// grid gutter
$grid-gutters: (
  xs: $base-gutter,
  sm: $base-gutter,
  md: $base-gutter,
  lg: $base-gutter,
  xl: $base-gutter
) !default;

// grid column
$grid-columns: 12 !default;



// Heading
$h1-font-size: $base-font-size * 2.25 !default;
$h2-font-size: $base-font-size * 2 !default;
$h3-font-size: $base-font-size * 1.75 !default;
$h4-font-size: $base-font-size * 1.3 !default;
$h5-font-size: $base-font-size * 1.25 !default;
$h6-font-size: $base-font-size !default;

$heading-margin-bottom: $base-spacer !default;
$headings-font-weight:  500 !default;
$heading-line-height:   1.2 !default;



// .lead
$lead-letter-spacing: 0 !default;
$lead-line-height   : 1.6 !default;
$lead-top-spacer    : $base-spacer !default;



// Base transition
$base-transition: .2s ease-out;

// Base border-radius
$base-border-radius: .5rem !default;



// Base box-shadw
$base-box-shadow:       0 .5rem 1rem rgba($black, .15) !default;
$base-box-shadow-small: 0 .125rem .25rem rgba($black, .075) !default;
$base-box-shadow-large: 0 1rem 3rem rgba($black, .175) !default;



// Anchor links
$base-link-color:            #0033cc !default;
$base-link-decoration:       none    !default;
$base-link-hover-color:      inherit !default;
$base-link-hover-decoration: none    !default;



// Btn
$btn-font-size-normal: 1rem !default;
$btn-font-size-small:  $btn-font-size-normal * .8125 !default;
$btn-font-size-large:  $btn-font-size-normal * 1.25 !default;
$btn-font-sizes: (
  'small':  $btn-font-size-small,
  'normal': $btn-font-size-normal,
  'large':  $btn-font-size-large
) !default;

// border 太さ
$btn-border-width: 1px !default;

// padding 左右
$btn-x-padding-small:  .875em !default;
$btn-x-padding-normal: 1em !default;
$btn-x-padding-large:  1.125em !default;
$btn-x-paddings: (
  'small':  $btn-x-padding-small,
  'normal': $btn-x-padding-normal,
  'large':  $btn-x-padding-large
) !default;

// padding 上下
$btn-y-padding-small:  .5em !default;
$btn-y-padding-normal: .625em !default;
$btn-y-padding-large:  .625em !default;
$btn-y-paddings: (
  'small':  $btn-y-padding-small,
  'normal': $btn-y-padding-normal,
  'large':  $btn-y-padding-large
) !default;

// background color
$btn-background-colors: (
  'accent': $accent,
  'uno'   : $uno,
  'dos'   : $dos,
  'tres'  : $tres,
  'cuatro': $cuatro,
  'cinco' : $cinco
) !default;

$btn-hover-background-colors: (
  'accent': lighten( $accent, 7.5% ),
  'uno'   : lighten( $uno, 7.5% ),
  'dos'   : lighten( $dos, 7.5% ),
  'tres'  : lighten( $tres, 7.5% ),
  'cuatro': lighten( $cuatro, 7.5% ),
  'cinco' : lighten( $cinco, 7.5% )
) !default;

// text color
$btn-colors: (
  'accent': $white,
  'uno'   : $white,
  'dos'   : $white,
  'tres'  : $white,
  'cuatro': $white,
  'cinco' : $white
) !default;

$btn-hover-colors: (
  'accent': $white,
  'uno'   : $white,
  'dos'   : $white,
  'tres'  : $white,
  'cuatro': $white,
  'cinco' : $white
) !default;



// Horizontal line
$hr-border  : 1px solid $gray-400 !default;
$hr-y-gutter: $base-gutter !default;



// Table
$table-border-color:        var(--gray-500) !default;
$table-th-background-color: #E7EAF7 !default;
$table-td-background-color: $white    !default;



// Label
$label-font-size: .75em;

$label-padding-y: (
  xs: .425em,
  sm: .425em,
  md: .5em,
  lg: .4em,
  xl: .4em
) !default;

$label-padding-x: (
  xs: .6em,
  sm: .6em,
  md: .6em,
  lg: .6em,
  xl: .6em
) !default;

// 角丸
$label-border-radius: 0px !default;

// アウトライン時のボーダーの太さ
$label-border-width: 1px !default;

// background color
$label-background-colors: (
  'base'  : $base,
  'accent': $accent,
  'uno'   : $uno,
  'dos'   : $dos,
  'tres'  : $tres,
  'cuatro': $cuatro,
  'cinco' : $cinco
) !default;

// text color
$label-colors: (
  'base'  : $white,
  'accent': $white,
  'uno'   : $white,
  'dos'   : $white,
  'tres'  : $white,
  'cuatro': $white,
  'cinco' : $white
) !default;



// Tab
$tab-current-class-name:      '.js-tab-current';
$tab-current-view-class-name: '.js-tab-current-view';



// breakpoint ごとの比率
$scale-sm: math.div(map-get($root-font-size, sm), map-get($root-font-size, xs));
$scale-md: math.div(map-get($root-font-size, md), map-get($root-font-size, xs));
$scale-lg: math.div(map-get($root-font-size, lg), map-get($root-font-size, xs));
$scale-xl: math.div(map-get($root-font-size, xl), map-get($root-font-size, xs));

$scales: (
  sm: $scale-sm,
  md: $scale-md,
  lg: $scale-lg,
  xl: $scale-xl
) !default;



// z-index
$z-index-header : 2 !default;
$z-index-nav    : 3 !default;
$z-index-main   : 0 !default;
$z-index-footer : 1 !default;
$z-index-pagetop: 2 !default;
$z-index-loading: 5 !default;
