@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@500&display=swap");
/*$btn01: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill=""><path d="M44.7,13.8h4.6V16h-4.6V13.8z"/><path class="st1" d="M6.9,18.3h19.5v2.3H9.2v21.8h24.1V28.6h9.2v13.8h3.4V20.6h-1.1v-2.3h3.4v24.1h1.1c0.6,0,1.1,0.5,1.1,1.1	c0,0.6-0.5,1.1-1.1,1.1H5.7c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1h1.1V18.3z M35.5,42.4h4.6V30.9h-4.6V42.4z"/><path class="st1" d="M30.9,36.7h-1.1v-8H12.6v8h-1.1V39h19.5V36.7z M14.9,30.9v5.7h12.6v-5.7H14.9z"/><path class="st0" d="M5.7,13.8V16h20.6v-2.3H5.7z"/><path class="st0" d="M39,17.2h-2.3v-2.3h-2.3v2.3h-2.3v2.3h2.3v2.3h2.3v-2.3H39V17.2z"/><path class="st1" d="M42.4,13.7c0-1.3-1-2.3-2.3-2.3h-9.2c-1.3,0-2.3,1-2.3,2.3v9.2c0,1.3,1,2.3,2.3,2.3h9.2c1.3,0,2.3-1,2.3-2.3V13.7z M30.9,13.7h9.2v9.2h-9.2V13.7z"/></svg>') !default;*/
/*$top-arrow: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill=""><ellipse class="st0" cx="13.1" cy="13.2" rx="12.5" ry="12.5"/><path class="st1" d="M10.8,8.8l4.7,4.7l-4.7,4.7"/></svg>') !default;*/
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transY {
  0% {
    margin-top: -16px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading-text-opacity {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* メインビジュアル */
.visual-wrapper {
  height: 68vh;
}
@media screen and (min-width: 992px) {
  .visual-wrapper {
    height: calc(100vh - 183px);
  }
}
.visual-wrapper .top-slide {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.visual-wrapper .top-slide-container {
  text-align: center;
}
.visual-wrapper .top-slide-container .top-slide-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: slider-1 24s linear infinite;
}
.visual-wrapper .top-slide-container .top-slide-image::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
}
.visual-wrapper .top-slide-container .top-slide-text {
  opacity: 0;
  animation: slider-2 24s linear infinite;
  color: var(--white);
  font-family: "Noto Serif JP", serif;
  font-size: min(5vw, 24px);
  letter-spacing: 0.1em;
  line-height: 150%;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
}
@media screen and (min-width: 992px) {
  .visual-wrapper .top-slide-container .top-slide-text {
    font-size: min(3vw, 40px);
  }
}
@media screen and (min-width: 992px) {
  .visual-wrapper .top-slide-container .top-slide-text .top-slide-br {
    display: none;
  }
}
.visual-wrapper .top-slide-container .top-slide-text .top-slide-br-lg {
  display: none;
}
@media screen and (min-width: 992px) {
  .visual-wrapper .top-slide-container .top-slide-text .top-slide-br-lg {
    display: inline;
  }
}
.visual-wrapper .top-slide-container.one .top-slide-image {
  background-image: url("../../../images/top/top-slide/top-slide-m01.jpg");
  animation-delay: -2s;
}
@media screen and (min-width: 768px) {
  .visual-wrapper .top-slide-container.one .top-slide-image {
    background-image: url("../../../images/top/top-slide/top-slide01.jpg");
  }
}
.visual-wrapper .top-slide-container.one .top-slide-image::after {
  background: rgba(255, 255, 255, 0.15);
}
.visual-wrapper .top-slide-container.one .top-slide-text {
  animation-delay: -2s;
  top: 25%;
}
@media screen and (min-width: 992px) {
  .visual-wrapper .top-slide-container.one .top-slide-text {
    right: 4.5rem;
    left: initial;
    margin-right: initial;
    margin-left: auto;
  }
}
.visual-wrapper .top-slide-container.two .top-slide-image {
  background-image: url("../../../images/top/top-slide/top-slide-m02.jpg");
  animation-delay: 6s;
}
@media screen and (min-width: 768px) {
  .visual-wrapper .top-slide-container.two .top-slide-image {
    background-image: url("../../../images/top/top-slide/top-slide02.jpg");
  }
}
.visual-wrapper .top-slide-container.two .top-slide-text {
  animation-delay: 6s;
  top: 13%;
}
.visual-wrapper .top-slide-container.three .top-slide-image {
  background-image: url("../../../images/top/top-slide/top-slide-m03.jpg");
  animation-delay: 14s;
}
@media screen and (min-width: 768px) {
  .visual-wrapper .top-slide-container.three .top-slide-image {
    background-image: url("../../../images/top/top-slide/top-slide03.jpg");
  }
}
.visual-wrapper .top-slide-container.three .top-slide-text {
  animation-delay: 14s;
  top: 42%;
}
@media screen and (min-width: 992px) {
  .visual-wrapper .top-slide-container.three .top-slide-text {
    right: 4rem;
    left: initial;
    margin-right: initial;
    margin-left: auto;
    text-align: left;
  }
}
@keyframes slider-1 {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  4.16% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  41.66% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    opacity: 0;
  }
}
@keyframes slider-2 {
  0% {
    opacity: 0;
  }
  4.16% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  41.66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* 各コンテンツ共有 */
.top-section-wrapper {
  padding: 3.5rem 1rem;
  background-color: #fafafa;
}
.top-section-wrapper.top-link {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.top-section-wrapper.caution {
  padding-top: 0;
  padding-bottom: 3.5rem;
}
.top-section-wrapper.news {
  padding-top: 0;
  padding-bottom: 0;
}
.top-section-wrapper.guide {
  background-color: #fff;
}
.top-section-wrapper.banner {
  background-color: #ddd;
  padding-right: 0;
}
@media screen and (min-width: 992px) {
  .top-section-wrapper.banner {
    padding-right: 1rem;
  }
}
.top-section-wrapper .top-inner-container {
  max-width: 1200px;
  margin: 0 auto;
  container-type: inline-size;
  /* 各コンテンツ共有(タイトル)*/
}
.top-section-wrapper .top-inner-container.white {
  background-color: var(--white);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 992px) {
  .top-section-wrapper .top-inner-container.white {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.top-section-wrapper .top-inner-container .top-heading {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 1rem;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .top-section-wrapper .top-inner-container .top-heading {
    font-size: 1.7rem;
    width: auto;
    margin-bottom: 0;
    margin-right: 3rem;
  }
}
.top-section-wrapper .top-inner-container .top-heading.blue {
  color: var(--base);
}

/* メインビジュアル下のボタン */
.top-link-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.top-link-container .top-link-item {
  width: calc(50% - 0.5rem);
}
.top-link-container .top-link-item:nth-child(1) {
  width: 100%;
}
.top-link-container .top-link-item .top-link-btn {
  background-color: white;
  padding: 1.5rem;
  box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: ease 0.2s;
  overflow: hidden;
  /*&.btn01:hover .top-link-btn-icon {
    background-image: set-svg($link-01, #fff);
  }*/
  /*&.btn02:hover .top-link-btn-icon {
    background-image: set-svg($link-02, #fff);
  }*/
  /*&.btn03:hover .top-link-btn-icon {
    background-image: set-svg($link-03, #fff);
  }*/
  /*&.btn04:hover .top-link-btn-icon {
    background-image: set-svg($link-04, #fff);
  }*/
  /*&.btn05:hover .top-link-btn-icon {
    background-image: set-svg($link-05, #fff);
  }*/
  /*&.btn01:hover {
    &::before {
      background-color: #ce7272;
    }
  }
  &.btn02:hover {
    &::before {
      background-color: #689e88;
    }
  }
  &.btn03:hover {
    &::before {
      background-color: #5694ae;
    }
  }
  &.btn04:hover {
    &::before {
      background-color: #bab767;
    }
  }
  &.btn05:hover {
    &::before {
      background-color: #ad7be7;
    }
  }*/
  /*&:hover::before {
    animation: skewanime .5s forwards;
  }*/
  /*&:hover .top-link-btn-title {
    color: var(--white);
  }
  &:hover .top-link-btn-eng {
    color: var(--white);
  }*/
}
.top-link-container .top-link-item .top-link-btn::after {
  content: "";
  background-image: url("../../../images/top/top_link_btn/arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  transform: translateY(-50%);
}
.top-link-container .top-link-item .top-link-btn .top-link-btn-icon {
  height: 4rem;
  width: 4rem;
  margin-bottom: 1rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.top-link-container .top-link-item .top-link-btn.btn01 .top-link-btn-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23ce7272"%3e%3cpath class="st0" d="M44.7,13.7h4.6V16h-4.6V13.7z M49.3,42.4h-1.1V18.3h-3.4v2.3h1.1v21.8h-3.4V28.6h-9.2v13.8H9.2V20.6h17.2v-2.3H6.9v24.1H5.7c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h43.5c0.6,0,1.1-0.5,1.1-1.1C50.4,42.9,49.9,42.4,49.3,42.4z M40.1,42.4h-4.6V30.9h4.6V42.4z M29.8,36.7v-8H12.6v8h-1.1V39h19.5v-2.3H29.8z M27.5,36.7H14.9v-5.7h12.6V36.7z M5.7,13.7V16h20.6v-2.3H5.7zM39,17.2h-2.3v-2.3h-2.3v2.3h-2.3v2.3h2.3v2.3h2.3v-2.3H39V17.2z M40.1,11.5h-9.2c-1.3,0-2.3,1-2.3,2.3v9.2c0,1.3,1,2.3,2.3,2.3h9.2c1.3,0,2.3-1,2.3-2.3v-9.2C42.4,12.5,41.4,11.5,40.1,11.5z M40.1,22.9h-9.2v-9.2h9.2V22.9z"/%3e%3c/svg%3e');
}
@media (hover: hover) and (pointer: fine) {
  .top-link-container .top-link-item .top-link-btn:hover.btn01 .top-link-btn-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23fff"%3e%3cpath class="st0" d="M44.7,13.7h4.6V16h-4.6V13.7z M49.3,42.4h-1.1V18.3h-3.4v2.3h1.1v21.8h-3.4V28.6h-9.2v13.8H9.2V20.6h17.2v-2.3H6.9v24.1H5.7c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h43.5c0.6,0,1.1-0.5,1.1-1.1C50.4,42.9,49.9,42.4,49.3,42.4z M40.1,42.4h-4.6V30.9h4.6V42.4z M29.8,36.7v-8H12.6v8h-1.1V39h19.5v-2.3H29.8z M27.5,36.7H14.9v-5.7h12.6V36.7z M5.7,13.7V16h20.6v-2.3H5.7zM39,17.2h-2.3v-2.3h-2.3v2.3h-2.3v2.3h2.3v2.3h2.3v-2.3H39V17.2z M40.1,11.5h-9.2c-1.3,0-2.3,1-2.3,2.3v9.2c0,1.3,1,2.3,2.3,2.3h9.2c1.3,0,2.3-1,2.3-2.3v-9.2C42.4,12.5,41.4,11.5,40.1,11.5z M40.1,22.9h-9.2v-9.2h9.2V22.9z"/%3e%3c/svg%3e');
  }
  .top-link-container .top-link-item .top-link-btn:hover.btn02 .top-link-btn-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23fff"%3e%3cpath d="M35.5,32.1c-0.6-0.1-2.3,2.3-2.3,2.3h-3.8c-1.3,0-1.8,0-2.4,0c-0.4,0-0.9,0-1.7,0h-3.5c0,0-1.7-2.4-2.3-2.3c-6.2,1.4-12.6,5.5-12.6,9.8v6.2h41.2v-6.2C48.1,37.6,41.7,33.5,35.5,32.1z M45.8,45.8H9.2v-3.9c0-1,0.8-2.5,3-4.1c1.9-1.4,4.3-2.5,6.8-3.2c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.5,0.6,0.7c0.1,0.1,0.1,0.2,0.2,0.2l0,0.1l0,0l0.7,1h4.7c0.8,0,1.3,0,1.7,0c0.7,0,1.2,0,2.4,0h5l0.7-1l0,0l0,0l0-0.1c0-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.5,0.6-0.7c0,0,0.1-0.1,0.1-0.1c2.5,0.7,5,1.8,6.8,3.2c2.2,1.6,3,3.1,3,4.1V45.8z"/%3e%3cpath d="M36.7,43.5v-6.9H39v6.9H36.7z"/%3e%3cpath d="M41.2,41.2h-6.9V39h6.9V41.2z"/%3e%3cpath d="M27.5,6.9C21.2,6.9,16,12,16,18.3c0,6.3,5.1,11.5,11.5,11.5S39,24.7,39,18.3C39,12,33.8,6.9,27.5,6.9zM27.5,27.5c-5.1,0-9.2-4.1-9.2-9.2c0-5.1,4.1-9.2,9.2-9.2c5.1,0,9.2,4.1,9.2,9.2C36.7,23.4,32.6,27.5,27.5,27.5z"/%3e%3c/svg%3e');
  }
  .top-link-container .top-link-item .top-link-btn:hover.btn03 .top-link-btn-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23fff"%3e	%3cpath class="st0" d="M27.5,6.9c-11.4,0-20.6,9.2-20.6,20.6c0,4,1.1,7.8,3.1,10.9l1.2-2.5c-1.3-2.5-2-5.4-2-8.4		c0-10.1,8.2-18.3,18.3-18.3s18.3,8.2,18.3,18.3c0,3-0.7,5.9-2,8.4l1.2,2.5c2-3.2,3.1-6.9,3.1-10.9C48.1,16.1,38.9,6.9,27.5,6.9z"/%3e	%3cpath class="st0" d="M27.5,19.5c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C33.2,22,30.7,19.5,27.5,19.5z		 M27.5,28.6c-1.9,0-3.4-1.5-3.4-3.4s1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4S29.4,28.6,27.5,28.6z"/%3e	%3cpath class="st0" d="M38.9,28.9c0-0.1-0.1-0.2-0.2-0.3c0.1-0.7,0.2-1.5,0.2-2.3c0-6.3-5.1-11.5-11.5-11.5S16,20,16,26.4		c0,0.8,0.1,1.5,0.2,2.3c-0.1,0.1-0.1,0.2-0.2,0.3l-5.4,12.8c-1.3,3,0.9,6.4,4.2,6.4h3.9c0,0,0.1,0,0.1,0h17c0,0,0.1,0,0.1,0h3.9		c3.3,0,5.5-3.3,4.2-6.4L38.9,28.9z M36.8,45.7c-0.2,0.1-0.4,0.1-0.6,0.1h-0.1c-0.4,0-0.8-0.2-1.1-0.4c0,0-0.1-0.1-0.1-0.1l-2.2-5.6		c-0.2-0.6-0.9-0.9-1.5-0.6c-0.6,0.2-0.9,0.9-0.6,1.5l2.1,5.3H22.4l2.1-5.3c0.2-0.6-0.1-1.3-0.6-1.5c-0.6-0.2-1.3,0.1-1.5,0.6		l-2.2,5.6c0,0-0.1,0.1-0.1,0.1c-0.3,0.3-0.7,0.4-1.1,0.4h-0.1c-0.2,0-0.4,0-0.6-0.1c-0.9-0.4-1.3-1.4-0.9-2.3l2.8-6.6		c0.6-1.5,2.1-2.4,3.7-2.4h7.3c1.6,0,3.1,1,3.7,2.4l2.8,6.6C38,44.3,37.6,45.3,36.8,45.7z M40.1,45.8h-0.4c0.5-1,0.5-2.2,0.1-3.3		l-2.8-6.6c-1-2.3-3.3-3.8-5.8-3.8h-7.3c-2.5,0-4.8,1.5-5.8,3.8l-2.8,6.6c-0.5,1.1-0.4,2.3,0.1,3.3h-0.4c-1.6,0-2.7-1.7-2.1-3.2		l5.4-12.8c0.4-0.8,1.2-1.4,2.1-1.4h0.2c-0.3-0.7-0.4-1.5-0.5-2.3c-0.6,0-1.2,0.2-1.7,0.4c0-0.1,0-0.2,0-0.2c0-5.1,4.1-9.2,9.2-9.2		s9.2,4.1,9.2,9.2c0,0.1,0,0.2,0,0.2c-0.5-0.3-1.1-0.4-1.7-0.4c0,0.8-0.2,1.6-0.5,2.3h0.2c0.9,0,1.8,0.6,2.1,1.4l5.4,12.8		C42.8,44.2,41.7,45.8,40.1,45.8z"/%3e	%3cpath class="st0" d="M32.7,14.3l0.9-2.1c-1.9-0.8-4-1.2-6.1-1.3c-2.1,0-4.2,0.4-6.1,1.2l0.9,2.1c1.7-0.7,3.5-1,5.3-1		S31.1,13.6,32.7,14.3z"/%3e%3c/svg%3e');
  }
  .top-link-container .top-link-item .top-link-btn:hover.btn04 .top-link-btn-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23fff"%3e%3cpath class="st0" d="M26.8,47.9c0.4,0.3,0.9,0.3,1.3,0c0,0,0,0-0.7-0.9C26.8,47.9,26.8,47.9,26.8,47.9z"/%3e			%3cpath class="st0" d="M38.8,11.6c-3-3-7.1-4.7-11.3-4.7c-4.3,0-8.3,1.7-11.3,4.7c-3,3-4.7,7.1-4.7,11.4c0,7.2,3.9,13.4,7.7,17.8				c1.9,2.2,3.8,4,5.2,5.2c0.7,0.6,1.3,1.1,1.7,1.4c0.2,0.2,0.4,0.3,0.5,0.4c0.1,0,0.1,0.1,0.1,0.1l0,0l0,0l0,0l0.7-0.9l0.7,0.9l0,0				l0,0l0,0c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.2,0.5-0.4c0.4-0.3,1-0.8,1.7-1.4c1.4-1.2,3.3-3,5.2-5.2c3.8-4.4,7.7-10.6,7.7-17.8				C43.5,18.7,41.9,14.6,38.8,11.6z M34.1,39.3c-1.8,2.1-3.6,3.8-5,5c-0.6,0.6-1.2,1-1.6,1.3c-0.4-0.3-0.9-0.7-1.6-1.3				c-1.4-1.2-3.2-2.9-5-5c-3.7-4.2-7.2-10-7.2-16.3c0-3.7,1.5-7.2,4-9.8c2.6-2.6,6.1-4.1,9.7-4.1c3.6,0,7.1,1.5,9.7,4.1				c2.6,2.6,4,6.1,4,9.8C41.2,29.3,37.7,35.1,34.1,39.3z"/%3e			%3cpath class="st0" d="M27.5,16c-3.8,0-6.9,3.1-6.9,6.9c0,3.8,3.1,6.9,6.9,6.9c3.8,0,6.9-3.1,6.9-6.9C34.4,19.1,31.3,16,27.5,16z				 M27.5,27.5c-2.5,0-4.6-2.1-4.6-4.6c0-2.5,2.1-4.6,4.6-4.6c2.5,0,4.6,2.1,4.6,4.6C32.1,25.4,30,27.5,27.5,27.5z"/%3e%3c/svg%3e');
  }
  .top-link-container .top-link-item .top-link-btn:hover.btn05 .top-link-btn-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23fff"%3e%3cpath class="st0" d="M27.5,13.8c-4.4,0-8,3.8-8,8.6c0,4.7,3.6,8.6,8,8.6s8-3.8,8-8.6C35.5,17.6,31.9,13.8,27.5,13.8z M27.5,28.8c-3.3,0-6-2.9-6-6.4c0-3.6,2.7-6.4,6-6.4s6,2.9,6,6.4C33.5,25.9,30.8,28.8,27.5,28.8z M49.3,12.5c0-3.7-3-6.7-6.7-6.7c-2.4,0-4.6,1.3-5.7,3.2c-2.8-1.4-5.9-2.2-9.3-2.2C16.1,6.8,6.9,16,6.9,27.4c0,11.4,9.3,20.7,20.7,20.7c11.4,0,20.7-9.3,20.7-20.7c0-3.3-0.8-6.5-2.2-9.3C48,17,49.3,14.9,49.3,12.5z M38.9,11.7h2.9V8.8h1.4v2.9h2.9v1.4h-2.9v2.9h-1.4v-2.9h-2.9V11.7z M38.5,42.1c-0.3,0.4-0.6,0.8-0.9,1.1c-2.9,1.8-6.3,2.9-10,2.9c-3.8,0-7.2-1.1-10.2-3c-0.2-0.3-0.5-0.6-0.8-1c-0.3-0.4-0.5-0.9-0.5-1.3c0-1.1,0.8-2,1.9-2.2c7.1-1,12-0.9,19,0c1.1,0.1,1.8,1.1,1.8,2.1C38.9,41.2,38.8,41.7,38.5,42.1z M46.2,27.4c0,5-2,9.5-5.2,12.9c-0.2-1.9-1.6-3.6-3.6-3.8c-7.3-1-12.3-1.1-19.6,0c-2,0.3-3.4,1.9-3.6,3.9c-3.2-3.3-5.2-7.9-5.2-12.9c0-10.3,8.3-18.6,18.6-18.6c3,0,5.9,0.7,8.5,2c-0.1,0.5-0.2,1-0.2,1.6c0,3.7,3,6.7,6.7,6.7c0.5,0,1.1-0.1,1.6-0.2C45.4,21.5,46.2,24.4,46.2,27.4z"/%3e%3c/svg%3e');
  }
}
.top-link-container .top-link-item .top-link-btn.btn02 .top-link-btn-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23689e88"%3e%3cpath d="M35.5,32.1c-0.6-0.1-2.3,2.3-2.3,2.3h-3.8c-1.3,0-1.8,0-2.4,0c-0.4,0-0.9,0-1.7,0h-3.5c0,0-1.7-2.4-2.3-2.3c-6.2,1.4-12.6,5.5-12.6,9.8v6.2h41.2v-6.2C48.1,37.6,41.7,33.5,35.5,32.1z M45.8,45.8H9.2v-3.9c0-1,0.8-2.5,3-4.1c1.9-1.4,4.3-2.5,6.8-3.2c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.5,0.6,0.7c0.1,0.1,0.1,0.2,0.2,0.2l0,0.1l0,0l0.7,1h4.7c0.8,0,1.3,0,1.7,0c0.7,0,1.2,0,2.4,0h5l0.7-1l0,0l0,0l0-0.1c0-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.5,0.6-0.7c0,0,0.1-0.1,0.1-0.1c2.5,0.7,5,1.8,6.8,3.2c2.2,1.6,3,3.1,3,4.1V45.8z"/%3e%3cpath d="M36.7,43.5v-6.9H39v6.9H36.7z"/%3e%3cpath d="M41.2,41.2h-6.9V39h6.9V41.2z"/%3e%3cpath d="M27.5,6.9C21.2,6.9,16,12,16,18.3c0,6.3,5.1,11.5,11.5,11.5S39,24.7,39,18.3C39,12,33.8,6.9,27.5,6.9zM27.5,27.5c-5.1,0-9.2-4.1-9.2-9.2c0-5.1,4.1-9.2,9.2-9.2c5.1,0,9.2,4.1,9.2,9.2C36.7,23.4,32.6,27.5,27.5,27.5z"/%3e%3c/svg%3e');
}
.top-link-container .top-link-item .top-link-btn.btn03 .top-link-btn-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%235694ae"%3e	%3cpath class="st0" d="M27.5,6.9c-11.4,0-20.6,9.2-20.6,20.6c0,4,1.1,7.8,3.1,10.9l1.2-2.5c-1.3-2.5-2-5.4-2-8.4		c0-10.1,8.2-18.3,18.3-18.3s18.3,8.2,18.3,18.3c0,3-0.7,5.9-2,8.4l1.2,2.5c2-3.2,3.1-6.9,3.1-10.9C48.1,16.1,38.9,6.9,27.5,6.9z"/%3e	%3cpath class="st0" d="M27.5,19.5c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7C33.2,22,30.7,19.5,27.5,19.5z		 M27.5,28.6c-1.9,0-3.4-1.5-3.4-3.4s1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4S29.4,28.6,27.5,28.6z"/%3e	%3cpath class="st0" d="M38.9,28.9c0-0.1-0.1-0.2-0.2-0.3c0.1-0.7,0.2-1.5,0.2-2.3c0-6.3-5.1-11.5-11.5-11.5S16,20,16,26.4		c0,0.8,0.1,1.5,0.2,2.3c-0.1,0.1-0.1,0.2-0.2,0.3l-5.4,12.8c-1.3,3,0.9,6.4,4.2,6.4h3.9c0,0,0.1,0,0.1,0h17c0,0,0.1,0,0.1,0h3.9		c3.3,0,5.5-3.3,4.2-6.4L38.9,28.9z M36.8,45.7c-0.2,0.1-0.4,0.1-0.6,0.1h-0.1c-0.4,0-0.8-0.2-1.1-0.4c0,0-0.1-0.1-0.1-0.1l-2.2-5.6		c-0.2-0.6-0.9-0.9-1.5-0.6c-0.6,0.2-0.9,0.9-0.6,1.5l2.1,5.3H22.4l2.1-5.3c0.2-0.6-0.1-1.3-0.6-1.5c-0.6-0.2-1.3,0.1-1.5,0.6		l-2.2,5.6c0,0-0.1,0.1-0.1,0.1c-0.3,0.3-0.7,0.4-1.1,0.4h-0.1c-0.2,0-0.4,0-0.6-0.1c-0.9-0.4-1.3-1.4-0.9-2.3l2.8-6.6		c0.6-1.5,2.1-2.4,3.7-2.4h7.3c1.6,0,3.1,1,3.7,2.4l2.8,6.6C38,44.3,37.6,45.3,36.8,45.7z M40.1,45.8h-0.4c0.5-1,0.5-2.2,0.1-3.3		l-2.8-6.6c-1-2.3-3.3-3.8-5.8-3.8h-7.3c-2.5,0-4.8,1.5-5.8,3.8l-2.8,6.6c-0.5,1.1-0.4,2.3,0.1,3.3h-0.4c-1.6,0-2.7-1.7-2.1-3.2		l5.4-12.8c0.4-0.8,1.2-1.4,2.1-1.4h0.2c-0.3-0.7-0.4-1.5-0.5-2.3c-0.6,0-1.2,0.2-1.7,0.4c0-0.1,0-0.2,0-0.2c0-5.1,4.1-9.2,9.2-9.2		s9.2,4.1,9.2,9.2c0,0.1,0,0.2,0,0.2c-0.5-0.3-1.1-0.4-1.7-0.4c0,0.8-0.2,1.6-0.5,2.3h0.2c0.9,0,1.8,0.6,2.1,1.4l5.4,12.8		C42.8,44.2,41.7,45.8,40.1,45.8z"/%3e	%3cpath class="st0" d="M32.7,14.3l0.9-2.1c-1.9-0.8-4-1.2-6.1-1.3c-2.1,0-4.2,0.4-6.1,1.2l0.9,2.1c1.7-0.7,3.5-1,5.3-1		S31.1,13.6,32.7,14.3z"/%3e%3c/svg%3e');
}
.top-link-container .top-link-item .top-link-btn.btn04 .top-link-btn-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23bab767"%3e%3cpath class="st0" d="M26.8,47.9c0.4,0.3,0.9,0.3,1.3,0c0,0,0,0-0.7-0.9C26.8,47.9,26.8,47.9,26.8,47.9z"/%3e			%3cpath class="st0" d="M38.8,11.6c-3-3-7.1-4.7-11.3-4.7c-4.3,0-8.3,1.7-11.3,4.7c-3,3-4.7,7.1-4.7,11.4c0,7.2,3.9,13.4,7.7,17.8				c1.9,2.2,3.8,4,5.2,5.2c0.7,0.6,1.3,1.1,1.7,1.4c0.2,0.2,0.4,0.3,0.5,0.4c0.1,0,0.1,0.1,0.1,0.1l0,0l0,0l0,0l0.7-0.9l0.7,0.9l0,0				l0,0l0,0c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.2,0.5-0.4c0.4-0.3,1-0.8,1.7-1.4c1.4-1.2,3.3-3,5.2-5.2c3.8-4.4,7.7-10.6,7.7-17.8				C43.5,18.7,41.9,14.6,38.8,11.6z M34.1,39.3c-1.8,2.1-3.6,3.8-5,5c-0.6,0.6-1.2,1-1.6,1.3c-0.4-0.3-0.9-0.7-1.6-1.3				c-1.4-1.2-3.2-2.9-5-5c-3.7-4.2-7.2-10-7.2-16.3c0-3.7,1.5-7.2,4-9.8c2.6-2.6,6.1-4.1,9.7-4.1c3.6,0,7.1,1.5,9.7,4.1				c2.6,2.6,4,6.1,4,9.8C41.2,29.3,37.7,35.1,34.1,39.3z"/%3e			%3cpath class="st0" d="M27.5,16c-3.8,0-6.9,3.1-6.9,6.9c0,3.8,3.1,6.9,6.9,6.9c3.8,0,6.9-3.1,6.9-6.9C34.4,19.1,31.3,16,27.5,16z				 M27.5,27.5c-2.5,0-4.6-2.1-4.6-4.6c0-2.5,2.1-4.6,4.6-4.6c2.5,0,4.6,2.1,4.6,4.6C32.1,25.4,30,27.5,27.5,27.5z"/%3e%3c/svg%3e');
}
.top-link-container .top-link-item .top-link-btn.btn05 .top-link-btn-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23ad7be7"%3e%3cpath class="st0" d="M27.5,13.8c-4.4,0-8,3.8-8,8.6c0,4.7,3.6,8.6,8,8.6s8-3.8,8-8.6C35.5,17.6,31.9,13.8,27.5,13.8z M27.5,28.8c-3.3,0-6-2.9-6-6.4c0-3.6,2.7-6.4,6-6.4s6,2.9,6,6.4C33.5,25.9,30.8,28.8,27.5,28.8z M49.3,12.5c0-3.7-3-6.7-6.7-6.7c-2.4,0-4.6,1.3-5.7,3.2c-2.8-1.4-5.9-2.2-9.3-2.2C16.1,6.8,6.9,16,6.9,27.4c0,11.4,9.3,20.7,20.7,20.7c11.4,0,20.7-9.3,20.7-20.7c0-3.3-0.8-6.5-2.2-9.3C48,17,49.3,14.9,49.3,12.5z M38.9,11.7h2.9V8.8h1.4v2.9h2.9v1.4h-2.9v2.9h-1.4v-2.9h-2.9V11.7z M38.5,42.1c-0.3,0.4-0.6,0.8-0.9,1.1c-2.9,1.8-6.3,2.9-10,2.9c-3.8,0-7.2-1.1-10.2-3c-0.2-0.3-0.5-0.6-0.8-1c-0.3-0.4-0.5-0.9-0.5-1.3c0-1.1,0.8-2,1.9-2.2c7.1-1,12-0.9,19,0c1.1,0.1,1.8,1.1,1.8,2.1C38.9,41.2,38.8,41.7,38.5,42.1z M46.2,27.4c0,5-2,9.5-5.2,12.9c-0.2-1.9-1.6-3.6-3.6-3.8c-7.3-1-12.3-1.1-19.6,0c-2,0.3-3.4,1.9-3.6,3.9c-3.2-3.3-5.2-7.9-5.2-12.9c0-10.3,8.3-18.6,18.6-18.6c3,0,5.9,0.7,8.5,2c-0.1,0.5-0.2,1-0.2,1.6c0,3.7,3,6.7,6.7,6.7c0.5,0,1.1-0.1,1.6-0.2C45.4,21.5,46.2,24.4,46.2,27.4z"/%3e%3c/svg%3e');
}
.top-link-container .top-link-item .top-link-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -140%;
  width: 120%;
  height: 100%;
  transform: skewX(-10deg);
  z-index: 2;
}
@media (hover: hover) and (pointer: fine) {
  .top-link-container .top-link-item .top-link-btn:hover.btn01::before {
    background-color: #ce7272;
  }
  .top-link-container .top-link-item .top-link-btn:hover.btn02:before {
    background-color: #689e88;
  }
  .top-link-container .top-link-item .top-link-btn:hover.btn03:before {
    background-color: #5694ae;
  }
  .top-link-container .top-link-item .top-link-btn:hover.btn04:before {
    background-color: #bab767;
  }
  .top-link-container .top-link-item .top-link-btn:hover.btn05:before {
    background-color: #ad7be7;
  }
}
@media (hover: hover) and (pointer: fine) {
  .top-link-container .top-link-item .top-link-btn:hover::before {
    animation: skewanime 0.5s forwards;
  }
}
@keyframes skewanime {
  100% {
    left: -10%;
  }
}
.top-link-container .top-link-item .top-link-btn span, .top-link-container .top-link-item .top-link-btn::after {
  z-index: 3;
}
.top-link-container .top-link-item .top-link-btn .top-link-btn-title {
  color: #000;
  margin-bottom: 0.3rem;
}
.top-link-container .top-link-item .top-link-btn .top-link-btn-eng {
  color: #808080;
  font-family: Times New Roman;
  font-size: 0.7rem;
}
.top-link-container .top-link-item .top-link-btn .top-link-btn-title {
  color: var(--gray-dark);
}
@media (hover: hover) and (pointer: fine) {
  .top-link-container .top-link-item .top-link-btn:hover .top-link-btn-title {
    color: var(--white);
  }
  .top-link-container .top-link-item .top-link-btn:hover .top-link-btn-eng {
    color: var(--white);
  }
}
@container (min-width: 960px) {
  .top-link-container .top-link-item {
    width: calc(19.2% - 0.5rem);
  }
  .top-link-container .top-link-item:nth-child(1) {
    width: calc(20.7% - 0.5rem);
  }
}

/* 重要なお知らせ */
.caution-container {
  display: flex;
  flex-direction: column;
}
.caution-container .caution-title {
  text-align: center;
  background-color: #e90000;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.8rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.caution-container .caution-news-list {
  background-color: #ffebeb;
  padding: 1.5rem 2rem;
  display: table;
}
@container (min-width: 730px) {
  .caution-container .caution-news-list {
    padding: 1.5rem 2.5rem;
  }
}
.caution-container .caution-news-list .caution-news-container {
  margin-top: 1.2rem;
}
.caution-container .caution-news-list .caution-news-container:nth-child(1) {
  margin-top: 0;
}
.caution-container .caution-news-list .caution-news-container .date {
  padding-bottom: 0.4rem;
  display: flex;
  align-items: center;
}
.caution-container .caution-news-list .caution-news-container .date::before {
  content: "";
  display: block;
  height: 1rem;
  width: 1rem;
  margin-right: 0.2rem;
  background-image: url("../../../images/top/imnews_mark.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.caution-container .caution-news-list .caution-news-container .category {
  display: none;
}
.caution-container .caution-news-list .caution-news-container .title {
  display: inline;
}
.caution-container .caution-news-list .caution-news-container .title a {
  color: #333;
}
@media (hover: hover) and (pointer: fine) {
  .caution-container .caution-news-list .caution-news-container .title a:hover {
    color: #adb5bd;
  }
}
.caution-container .caution-news-list .caution-news-container .title a[target=_blank]:not(a[href$=".pdf"]) {
  display: inline-flex;
  align-items: center;
  position: relative;
}
.caution-container .caution-news-list .caution-news-container .title a[target=_blank]:not(a[href$=".pdf"])::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve" fill="%230e67b5"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5l0,0v14c0,1.1,0.9,2,2,2l0,0h14c1.1,0,2-0.9,2-2v-7h-2V19zM14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  min-width: 1.25rem;
  position: relative;
  margin-left: 0.5rem;
  transition: opacity 0.2s ease-out;
}
.caution-container .caution-news-list .caution-news-container .title a[href$=".pdf"] {
  display: inline-flex;
  align-items: center;
  position: relative;
}
.caution-container .caution-news-list .caution-news-container .title a[href$=".pdf"]::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.375rem;
  height: 1.375rem;
  min-width: 1.375rem;
  position: relative;
  margin-left: 0.5rem;
  transition: opacity 0.2s ease-out;
}
@container (min-width: 730px) {
  .caution-container .caution-news-list .caution-news-container {
    display: table-row;
    margin-top: 0;
  }
  .caution-container .caution-news-list .caution-news-container .date, .caution-container .caution-news-list .caution-news-container .title {
    display: table-cell;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  .caution-container .caution-news-list .caution-news-container .date {
    display: flex;
    width: 115px;
    padding-right: 0.9rem;
  }
  .caution-container .caution-news-list .caution-news-container .title {
    width: 100%;
  }
}
@container (min-width: 922px) {
  .caution-container .caution-news-list .caution-news-container .date {
    width: 130px;
  }
}

@container (min-width: 620px) {
  .caution-container {
    flex-direction: row;
  }
  .caution-container .caution-title {
    width: 20%;
    padding-top: 1.8rem;
  }
  .caution-container .caution-news-list {
    width: 80%;
  }
}
/* 受付時間  */
.time-container {
  display: flex;
  flex-direction: column;
}
.time-container .time-title {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  background-color: #3986CE;
  color: var(--white);
  padding: 0.8rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.time-container .time-title span {
  font-size: 0.8rem;
  padding-left: 1rem;
}
.time-container .time-title::before {
  content: "";
  display: inline-block;
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
  background-image: url("../../../images/top/time-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.time-container .time-text {
  border: 2px solid #3986CE;
  background-color: #fff;
  padding: 1rem;
}
.time-container .time-text .time-table {
  display: table;
  background: #fff;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: solid 1px #e1e1e1;
}
.time-container .time-text .time-table .time-row {
  padding-top: 0.6rem;
}
.time-container .time-text .time-table .time-row .date {
  font-weight: 600;
  color: #3986CE;
}
.time-container .time-text .time-table .time-row .time {
  display: inline;
}
.time-container .time-text .time-table .time-row .tyu {
  display: block;
}
@container (min-width: 500px) {
  .time-container .time-text .time-table .time-row .tyu {
    display: inline;
    padding-left: 1rem;
  }
}
.time-container .time-text .time-table .time-row:nth-child(1) {
  padding-top: 0;
}
.time-container .time-text .time-desc {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  text-align: center;
}
.time-container .time-text .time-desc p {
  width: 100%;
}
.time-container .time-text .time-desc p span {
  color: #fb3d3d;
}
.time-container .time-text .time-desc div {
  width: calc(50% - 0.5rem);
}
@container (min-width: 1150px) {
  .time-container .time-text .time-desc {
    justify-content: space-between;
    text-align: left;
    margin-top: 2rem;
  }
  .time-container .time-text .time-desc p {
    width: 40%;
  }
  .time-container .time-text .time-desc div {
    width: calc(30% - 1.01rem);
  }
  .time-container .time-text .time-desc div a {
    padding: 0.6rem 1rem;
  }
}

@container (min-width: 800px) {
  .time-container {
    flex-direction: row;
  }
  .time-container .time-title {
    width: 30%;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .time-container .time-title::before {
    height: 7rem;
    width: 7rem;
    margin-right: initial;
    margin-bottom: 0.8rem;
  }
  .time-container .time-title span {
    padding-left: initial;
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  .time-container .time-text {
    width: 80%;
    padding: 2rem;
  }
  .time-container .time-text .time-table {
    background-color: #FAFAFA;
    padding-bottom: 0rem;
    border-bottom: none;
    padding: 1rem;
  }
  .time-container .time-text .time-table .time-row {
    display: table-row;
  }
  .time-container .time-text .time-table .time-row:nth-child(1) div {
    padding-bottom: 0.7rem;
    border-bottom: solid 1px #e1e1e1;
  }
  .time-container .time-text .time-table .time-row:nth-child(2) div {
    padding-top: 0.7rem;
  }
  .time-container .time-text .time-table .time-row .date, .time-container .time-text .time-table .time-row .time, .time-container .time-text .time-table .time-row .tyu {
    display: table-cell;
  }
  .time-container .time-text .time-table .time-row .date, .time-container .time-text .time-table .time-row .time {
    width: 0;
    white-space: nowrap;
  }
  .time-container .time-text .time-table .time-row .date {
    padding-right: 1.75rem;
  }
  .time-container .time-text .time-table .time-row .time {
    padding-right: 1rem;
  }
  .time-container .time-text .time-table .time-row .tyu {
    padding-left: initial;
  }
}
/* 当院をご利用の方へ */
.top-heading-eng {
  color: var(--base);
  font-family: Times New Roman;
  font-size: 0.8rem;
  margin-bottom: 0.7rem;
}

.guide-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
}
.guide-container .top-guide-item {
  width: 100%;
}
.guide-container .top-guide-item .top-guide-btn {
  display: block;
  padding: 1.7rem 2rem 1.7rem 1rem;
  border: solid 2px var(--base);
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: ease 0.2s;
  overflow: hidden;
  /*&:hover {
    .top-guide-btn-icon {
      &.btn01 {
        background-image: set-svg($guide-01, #fff);
      }
      &.btn02 {
        background-image: set-svg($guide-02, #fff);
      }
      &.btn03 {
        background-image: set-svg($guide-03, #fff);
      }
      &.btn04 {
        background-image: set-svg($guide-04, #fff);
      }
      &.btn05 {
        background-image: set-svg($guide-05, #fff);
      }
    }
  }*/
  /*&:hover::before {
    animation: skewanime .5s forwards;
  }*/
  /*&:hover {
    color: var(--base);
  }
  &::before {
  background-color: var(--base);
  }*/
  /*&:hover::after {
    margin-right: -.3rem;
    background-image: set-svg($chev, $white);
  }*/
  /*&:hover .top-guide-btn-title {
    color: $white;
  }*/
}
.guide-container .top-guide-item .top-guide-btn .top-guide-btn-icon {
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.guide-container .top-guide-item .top-guide-btn .top-guide-btn-icon.btn01 {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%231d3e87"%3e%3cpath d="M39.6,32.3L39.6,32.3c2.3,0,4.2-1.8,4.2-4.1c0-1.5-0.8-2.9-2.1-3.6V11.5c0-1.2-0.9-2.1-2.1-2.1h-6.2c-0.6,0-1-0.5-1-1V6.2h-2.1v2.1c0,1.4,0.9,2.5,2.1,2.9v1.2h-1c-0.6,0-1,0.5-1,1v6.2c0,0.6,0.5,1,1,1h4.2c0.6,0,1-0.5,1-1v-6.2c0-0.6-0.5-1-1-1h-1v-1h5.2V24H20.9c-0.1,0-0.2,0-0.2-0.1l-0.4-0.4l0.4-0.4c1.5-1.5,1.5-4,0-5.6l-1.9-1.9c-1.5-1.5-4-1.5-5.6,0l-0.7,0.7c-1.4-0.4-3,0-4.1,1.1c-1.6,1.6-1.6,4.3,0,5.9l0.9,0.9v11.1H6.2v2.1h3.2c-0.7,0.7-1.1,1.6-1.1,2.6c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6c0-1-0.4-1.9-1.1-2.6h20.9c-0.7,0.7-1.1,1.6-1.1,2.6c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6c0-1-0.4-1.9-1.1-2.6h3.2v-2.1h-4.2V32.3z M34.4,18.8h-2.1v-4.2h2.1V18.8z M14.8,17.2c0.7-0.7,1.9-0.7,2.6,0l1.9,1.9c0.7,0.7,0.7,1.9,0,2.6l-0.4,0.4l-4.5-4.5L14.8,17.2z M10,19c0.8-0.8,2.1-0.8,2.9,0l6.4,6.4c0.4,0.5,1.1,0.7,1.7,0.7h18.7c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H18.2L10,22C9.2,21.2,9.2,19.8,10,19z M12,41.7c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6C13.5,41,12.8,41.7,12,41.7z M11.5,35.4v-9l5.5,5.5c0.2,0.2,0.5,0.3,0.8,0.3h19.7v3.1H11.5z M38,41.7c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6C39.6,41,38.9,41.7,38,41.7z"/%3e%3c/svg%3e');
}
@container (min-width: 600px) {
  .guide-container .top-guide-item .top-guide-btn .top-guide-btn-icon.btn01 {
    height: 4.7rem;
    width: 4.7rem;
  }
}
.guide-container .top-guide-item .top-guide-btn .top-guide-btn-icon.btn02 {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%231d3e87"%3e%3cpath d="M38.1,17.1l-5.2-8.3c-0.5-0.8-1.2-1.2-2-1.4c-0.8-1.9-2.6-3.2-4.8-3.2c-2.9,0-5.2,2.3-5.2,5.2c0,2.9,2.3,5.2,5.2,5.2c1,0,1.9-0.3,2.6-0.7l1.1,1.8h-10c0,0-0.1,0-0.1,0c-2.4,0.1-4.5,1-5.9,2.6c-1.5,1.6-2.2,3.7-2.3,5.7c0,2,0.7,4.1,2.2,5.7c1.3,1.4,3,2.3,5.1,2.5v0.1l0,0v0v10.4c0,1.7,1.3,3,3,3.1c1.7,0.1,3.1-1.2,3.3-2.8l0.8-7.6h0.6l0.8,7.6c0.2,1.7,1.6,2.9,3.3,2.8c1.7-0.1,3-1.5,3-3.1v-8.3l0,0V21.9h2.1c1.1,0,2.2-0.6,2.7-1.6C38.7,19.3,38.7,18.1,38.1,17.1z M28.6,7.8c-1.5,0.9-1.9,2.8-1,4.3l0,0c-0.5,0.3-1,0.4-1.5,0.4c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1c1.1,0,2.1,0.6,2.6,1.4C28.6,7.7,28.6,7.7,28.6,7.8z M36.3,19.3c-0.2,0.3-0.5,0.5-0.9,0.5h-3.1c-0.6,0-1,0.5-1,1v13.5l0,0v8.3c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1.1-0.9l-0.9-8.5c-0.1-0.5-0.5-0.9-1-0.9h-2.5c-0.5,0-1,0.4-1,0.9l-0.9,8.5c-0.1,0.6-0.5,1-1.1,0.9c-0.6,0-1-0.5-1-1V32.3l0.1,0c0.3,0,0.6,0,0.9,0c0.6,0,1.3,0,1.9-0.2c0.6-0.1,1.2-0.4,1.6-1c0.4-0.6,0.6-1.3,0.6-2c0-0.8-0.2-1.5-0.7-2.1c-0.5-0.5-1.1-0.8-1.7-0.9C23,26.1,22.3,26,21.7,26c-0.3,0-0.6,0-0.9,0v-5.2c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-1.4,0.1-2.3,0.6-3,1.3c-0.7,0.8-1.1,1.8-1.1,2.9c0,1.1,0.4,2.1,1.1,2.9c0.6,0.6,1.5,1.1,2.7,1.3c0.1,0,0.2,0.1,0.4,0.1c0.3,0,0.7,0,1,0l0,0c0.3,0,0.6,0,0.9,0c0.6,0,1.1,0,1.5,0.1c0.4,0.1,0.5,0.2,0.6,0.3c0.1,0.1,0.2,0.2,0.2,0.7c0,0.5-0.1,0.7-0.2,0.7c0,0.1-0.1,0.2-0.5,0.2c-0.3,0.1-0.8,0.1-1.4,0.1c-0.3,0-0.6,0-0.9,0l-0.1,0c-0.3,0-0.6,0-0.9,0c-0.1,0-0.2,0-0.2,0c-2-0.1-3.6-0.8-4.6-2c-1.1-1.2-1.6-2.8-1.6-4.3c0-1.5,0.6-3.1,1.7-4.3c1.1-1.1,2.6-1.9,4.6-1.9c0,0,0,0,0.1,0h11.8c0.4,0,0.7-0.2,0.9-0.5c0.2-0.3,0.2-0.7,0-1.1L29.3,11c-0.3-0.5-0.2-1.1,0.3-1.4c0.5-0.3,1.1-0.2,1.4,0.3l5.2,8.3C36.5,18.5,36.5,18.9,36.3,19.3z M18.8,22.2v3.6c-0.2-0.1-0.4-0.3-0.5-0.4c-0.3-0.3-0.5-0.8-0.5-1.4c0-0.6,0.2-1.1,0.6-1.5C18.4,22.4,18.5,22.3,18.8,22.2z"/%3e%3c/svg%3e');
}
@container (min-width: 600px) {
  .guide-container .top-guide-item .top-guide-btn .top-guide-btn-icon.btn02 {
    height: 4.7rem;
    width: 4.7rem;
  }
}
.guide-container .top-guide-item .top-guide-btn .top-guide-btn-icon.btn03 {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%231d3e87"%3e%3cpath d="M42.9,41.2l-2.8-2.8c0.2-0.8,0-1.7-0.6-2.3l-1.2-1.2c0.8-2.2,0.4-4.7-1.3-6.5L22.4,13.9l1.7-1.7c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7,0L19,13.9l-4.3-4.3l0.9-0.9c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7,0l-6.8,6.8c-0.5,0.5-0.5,1.2,0,1.7c0.5,0.5,1.2,0.5,1.7,0l0.9-0.9l4.3,4.3l-3.4,3.4c-0.5,0.5-0.5,1.2,0,1.7c0.5,0.5,1.2,0.5,1.7,0l1.7-1.7l14.5,14.5c1.7,1.7,4.3,2.2,6.5,1.4l1.2,1.2c0.6,0.6,1.5,0.8,2.3,0.6l2.8,2.8c0.5,0.5,1.2,0.5,1.7,0C43.4,42.4,43.4,41.7,42.9,41.2z M11.1,13.3l2.2-2.2l4.3,4.3l-2.2,2.2L11.1,13.3z M15.4,21l5.6-5.6l4.6,4.6L20,25.6L15.4,21zM36.3,34.1l-2.2,2.2c-1.4,0.6-3.1,0.3-4.2-0.9l-0.5-0.5l1.9-1.9c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7,0l-1.9,1.9l-1.7-1.7l1.9-1.9c0.5-0.5,0.5-1.2,0-1.7s-1.2-0.5-1.7,0l-1.9,1.9l-2.6-2.6l5.6-5.6l8.2,8.2C36.6,31,36.9,32.7,36.3,34.1z M37.8,37.8c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0l-0.9-0.9c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2l0.9,0.9c0,0,0,0,0,0C38,37.6,37.9,37.7,37.8,37.8z"/%3e%3c/svg%3e');
}
.guide-container .top-guide-item .top-guide-btn .top-guide-btn-icon.btn04 {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%231d3e87"%3e%3cpath class="st0" d="M26,30.2h5.2v2.1H26v5.2H24v-5.2h-5.2v-2.1H24V25H26V30.2z M33.3,18.8h-1v-2.1c0-1.2-0.9-2.1-2.1-2.1H26V8.3h8.3V6.2H16.7c-1.4,0-2.7,0.8-3.6,1.6c-0.9,0.8-1.6,1.8-2,2.5l1.8,1c0.3-0.5,0.9-1.4,1.6-2c0.8-0.7,1.5-1.1,2.2-1.1H24v6.2h-4.2c-1.2,0-2.1,0.9-2.1,2.1v2.1h-1c-3.5,0-6.2,2.8-6.2,6.2v15.6c0,1.7,1.4,3.1,3.1,3.1h22.9c1.7,0,3.1-1.4,3.1-3.1V25C39.6,21.5,36.8,18.8,33.3,18.8z M19.8,16.7h10.4v2.1H19.8V16.7z M37.5,40.6c0,0.6-0.5,1-1,1H13.5c-0.6,0-1-0.5-1-1V25c0-2.3,1.9-4.2,4.2-4.2h16.7c2.3,0,4.2,1.9,4.2,4.2V40.6z"/%3e%3c/svg%3e');
}
.guide-container .top-guide-item .top-guide-btn .top-guide-btn-icon.btn05 {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%231d3e87"%3e%3cpath d="M12.5,10.4c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-0.8,0-1.4,0.4-1.8,1h-0.3c-1.7,0-3.1,1.4-3.1,3.1v9.4h2.1v-9.4c0-0.6,0.5-1,1-1h0.3C11.1,10,11.7,10.4,12.5,10.4z"/%3e%3cpath d="M10.6,19.8H9.4H7.3c-0.6,0-1,0.5-1,1c0.2,2.7,1.4,5.2,3.3,7.1c1.4,1.4,3.1,2.4,5,2.9v2.5c0,1.4,0.9,2.5,2.1,2.9c0,4.1,3.4,7.5,7.6,7.5c4.2,0,7.6-3.4,7.6-7.6V32c0-2.2,1.7-3.9,3.9-3.9c2.2,0,3.9,1.7,3.9,3.9v2.5c-1.2,0.4-2.1,1.6-2.1,2.9c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1c0-1.4-0.9-2.5-2.1-2.9V32c0-3.3-2.7-6-6-6c-3.3,0-6,2.7-6,6v4.2c0,3-2.4,5.5-5.5,5.5c-3,0-5.4-2.4-5.5-5.4c1.2-0.4,2.1-1.6,2.1-2.9v-2.5c1.9-0.5,3.6-1.5,5-2.9c1.9-1.9,3.1-4.4,3.3-7.1c0.1-0.6-0.4-1-1-1v-9.4c0-1.7-1.4-3.1-3.1-3.1h-0.3c-0.4-0.6-1-1-1.8-1c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0.8,0,1.4-0.4,1.8-1H25c0.6,0,1,0.5,1,1v9.4h-1.2c-0.6,0-1,0.5-1.1,1c-0.2,1.2-0.8,2.4-1.7,3.3c-1.1,1.1-2.7,1.8-4.3,1.8c-1.6,0-3.2-0.6-4.3-1.8c-0.9-0.9-1.5-2-1.7-3.3C11.6,20.3,11.2,19.8,10.6,19.8z M25.6,21.9h1.2c-0.4,1.7-1.3,3.3-2.5,4.5c-1.8,1.8-4.1,2.7-6.6,2.7c-2.5,0-4.9-1-6.6-2.7c-1.3-1.3-2.1-2.8-2.5-4.5h1.2c0.4,1.4,1.1,2.7,2.1,3.7c1.5,1.5,3.6,2.4,5.8,2.4c2.2,0,4.2-0.9,5.8-2.4C24.5,24.5,25.2,23.3,25.6,21.9z M40.6,36.5c0.6,0,1,0.5,1,1s-0.5,1-1,1s-1-0.5-1-1S40,36.5,40.6,36.5z M18.8,31.2v2.1c0,0.6-0.5,1-1,1s-1-0.5-1-1v-2.1H18.8z"/%3e%3c/svg%3e');
}
@media (hover: hover) and (pointer: fine) {
  .guide-container .top-guide-item .top-guide-btn:hover .top-guide-btn-icon.btn01 {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23fff"%3e%3cpath d="M39.6,32.3L39.6,32.3c2.3,0,4.2-1.8,4.2-4.1c0-1.5-0.8-2.9-2.1-3.6V11.5c0-1.2-0.9-2.1-2.1-2.1h-6.2c-0.6,0-1-0.5-1-1V6.2h-2.1v2.1c0,1.4,0.9,2.5,2.1,2.9v1.2h-1c-0.6,0-1,0.5-1,1v6.2c0,0.6,0.5,1,1,1h4.2c0.6,0,1-0.5,1-1v-6.2c0-0.6-0.5-1-1-1h-1v-1h5.2V24H20.9c-0.1,0-0.2,0-0.2-0.1l-0.4-0.4l0.4-0.4c1.5-1.5,1.5-4,0-5.6l-1.9-1.9c-1.5-1.5-4-1.5-5.6,0l-0.7,0.7c-1.4-0.4-3,0-4.1,1.1c-1.6,1.6-1.6,4.3,0,5.9l0.9,0.9v11.1H6.2v2.1h3.2c-0.7,0.7-1.1,1.6-1.1,2.6c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6c0-1-0.4-1.9-1.1-2.6h20.9c-0.7,0.7-1.1,1.6-1.1,2.6c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6c0-1-0.4-1.9-1.1-2.6h3.2v-2.1h-4.2V32.3z M34.4,18.8h-2.1v-4.2h2.1V18.8z M14.8,17.2c0.7-0.7,1.9-0.7,2.6,0l1.9,1.9c0.7,0.7,0.7,1.9,0,2.6l-0.4,0.4l-4.5-4.5L14.8,17.2z M10,19c0.8-0.8,2.1-0.8,2.9,0l6.4,6.4c0.4,0.5,1.1,0.7,1.7,0.7h18.7c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H18.2L10,22C9.2,21.2,9.2,19.8,10,19z M12,41.7c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6C13.5,41,12.8,41.7,12,41.7z M11.5,35.4v-9l5.5,5.5c0.2,0.2,0.5,0.3,0.8,0.3h19.7v3.1H11.5z M38,41.7c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6C39.6,41,38.9,41.7,38,41.7z"/%3e%3c/svg%3e');
  }
  .guide-container .top-guide-item .top-guide-btn:hover .top-guide-btn-icon.btn02 {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23fff"%3e%3cpath d="M38.1,17.1l-5.2-8.3c-0.5-0.8-1.2-1.2-2-1.4c-0.8-1.9-2.6-3.2-4.8-3.2c-2.9,0-5.2,2.3-5.2,5.2c0,2.9,2.3,5.2,5.2,5.2c1,0,1.9-0.3,2.6-0.7l1.1,1.8h-10c0,0-0.1,0-0.1,0c-2.4,0.1-4.5,1-5.9,2.6c-1.5,1.6-2.2,3.7-2.3,5.7c0,2,0.7,4.1,2.2,5.7c1.3,1.4,3,2.3,5.1,2.5v0.1l0,0v0v10.4c0,1.7,1.3,3,3,3.1c1.7,0.1,3.1-1.2,3.3-2.8l0.8-7.6h0.6l0.8,7.6c0.2,1.7,1.6,2.9,3.3,2.8c1.7-0.1,3-1.5,3-3.1v-8.3l0,0V21.9h2.1c1.1,0,2.2-0.6,2.7-1.6C38.7,19.3,38.7,18.1,38.1,17.1z M28.6,7.8c-1.5,0.9-1.9,2.8-1,4.3l0,0c-0.5,0.3-1,0.4-1.5,0.4c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1c1.1,0,2.1,0.6,2.6,1.4C28.6,7.7,28.6,7.7,28.6,7.8z M36.3,19.3c-0.2,0.3-0.5,0.5-0.9,0.5h-3.1c-0.6,0-1,0.5-1,1v13.5l0,0v8.3c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1.1-0.9l-0.9-8.5c-0.1-0.5-0.5-0.9-1-0.9h-2.5c-0.5,0-1,0.4-1,0.9l-0.9,8.5c-0.1,0.6-0.5,1-1.1,0.9c-0.6,0-1-0.5-1-1V32.3l0.1,0c0.3,0,0.6,0,0.9,0c0.6,0,1.3,0,1.9-0.2c0.6-0.1,1.2-0.4,1.6-1c0.4-0.6,0.6-1.3,0.6-2c0-0.8-0.2-1.5-0.7-2.1c-0.5-0.5-1.1-0.8-1.7-0.9C23,26.1,22.3,26,21.7,26c-0.3,0-0.6,0-0.9,0v-5.2c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-1.4,0.1-2.3,0.6-3,1.3c-0.7,0.8-1.1,1.8-1.1,2.9c0,1.1,0.4,2.1,1.1,2.9c0.6,0.6,1.5,1.1,2.7,1.3c0.1,0,0.2,0.1,0.4,0.1c0.3,0,0.7,0,1,0l0,0c0.3,0,0.6,0,0.9,0c0.6,0,1.1,0,1.5,0.1c0.4,0.1,0.5,0.2,0.6,0.3c0.1,0.1,0.2,0.2,0.2,0.7c0,0.5-0.1,0.7-0.2,0.7c0,0.1-0.1,0.2-0.5,0.2c-0.3,0.1-0.8,0.1-1.4,0.1c-0.3,0-0.6,0-0.9,0l-0.1,0c-0.3,0-0.6,0-0.9,0c-0.1,0-0.2,0-0.2,0c-2-0.1-3.6-0.8-4.6-2c-1.1-1.2-1.6-2.8-1.6-4.3c0-1.5,0.6-3.1,1.7-4.3c1.1-1.1,2.6-1.9,4.6-1.9c0,0,0,0,0.1,0h11.8c0.4,0,0.7-0.2,0.9-0.5c0.2-0.3,0.2-0.7,0-1.1L29.3,11c-0.3-0.5-0.2-1.1,0.3-1.4c0.5-0.3,1.1-0.2,1.4,0.3l5.2,8.3C36.5,18.5,36.5,18.9,36.3,19.3z M18.8,22.2v3.6c-0.2-0.1-0.4-0.3-0.5-0.4c-0.3-0.3-0.5-0.8-0.5-1.4c0-0.6,0.2-1.1,0.6-1.5C18.4,22.4,18.5,22.3,18.8,22.2z"/%3e%3c/svg%3e');
  }
  .guide-container .top-guide-item .top-guide-btn:hover .top-guide-btn-icon.btn03 {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23fff"%3e%3cpath d="M42.9,41.2l-2.8-2.8c0.2-0.8,0-1.7-0.6-2.3l-1.2-1.2c0.8-2.2,0.4-4.7-1.3-6.5L22.4,13.9l1.7-1.7c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7,0L19,13.9l-4.3-4.3l0.9-0.9c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7,0l-6.8,6.8c-0.5,0.5-0.5,1.2,0,1.7c0.5,0.5,1.2,0.5,1.7,0l0.9-0.9l4.3,4.3l-3.4,3.4c-0.5,0.5-0.5,1.2,0,1.7c0.5,0.5,1.2,0.5,1.7,0l1.7-1.7l14.5,14.5c1.7,1.7,4.3,2.2,6.5,1.4l1.2,1.2c0.6,0.6,1.5,0.8,2.3,0.6l2.8,2.8c0.5,0.5,1.2,0.5,1.7,0C43.4,42.4,43.4,41.7,42.9,41.2z M11.1,13.3l2.2-2.2l4.3,4.3l-2.2,2.2L11.1,13.3z M15.4,21l5.6-5.6l4.6,4.6L20,25.6L15.4,21zM36.3,34.1l-2.2,2.2c-1.4,0.6-3.1,0.3-4.2-0.9l-0.5-0.5l1.9-1.9c0.5-0.5,0.5-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.7,0l-1.9,1.9l-1.7-1.7l1.9-1.9c0.5-0.5,0.5-1.2,0-1.7s-1.2-0.5-1.7,0l-1.9,1.9l-2.6-2.6l5.6-5.6l8.2,8.2C36.6,31,36.9,32.7,36.3,34.1z M37.8,37.8c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0l-0.9-0.9c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2l0.9,0.9c0,0,0,0,0,0C38,37.6,37.9,37.7,37.8,37.8z"/%3e%3c/svg%3e');
  }
  .guide-container .top-guide-item .top-guide-btn:hover .top-guide-btn-icon.btn04 {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23fff"%3e%3cpath class="st0" d="M26,30.2h5.2v2.1H26v5.2H24v-5.2h-5.2v-2.1H24V25H26V30.2z M33.3,18.8h-1v-2.1c0-1.2-0.9-2.1-2.1-2.1H26V8.3h8.3V6.2H16.7c-1.4,0-2.7,0.8-3.6,1.6c-0.9,0.8-1.6,1.8-2,2.5l1.8,1c0.3-0.5,0.9-1.4,1.6-2c0.8-0.7,1.5-1.1,2.2-1.1H24v6.2h-4.2c-1.2,0-2.1,0.9-2.1,2.1v2.1h-1c-3.5,0-6.2,2.8-6.2,6.2v15.6c0,1.7,1.4,3.1,3.1,3.1h22.9c1.7,0,3.1-1.4,3.1-3.1V25C39.6,21.5,36.8,18.8,33.3,18.8z M19.8,16.7h10.4v2.1H19.8V16.7z M37.5,40.6c0,0.6-0.5,1-1,1H13.5c-0.6,0-1-0.5-1-1V25c0-2.3,1.9-4.2,4.2-4.2h16.7c2.3,0,4.2,1.9,4.2,4.2V40.6z"/%3e%3c/svg%3e');
  }
  .guide-container .top-guide-item .top-guide-btn:hover .top-guide-btn-icon.btn05 {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23fff"%3e%3cpath d="M12.5,10.4c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-0.8,0-1.4,0.4-1.8,1h-0.3c-1.7,0-3.1,1.4-3.1,3.1v9.4h2.1v-9.4c0-0.6,0.5-1,1-1h0.3C11.1,10,11.7,10.4,12.5,10.4z"/%3e%3cpath d="M10.6,19.8H9.4H7.3c-0.6,0-1,0.5-1,1c0.2,2.7,1.4,5.2,3.3,7.1c1.4,1.4,3.1,2.4,5,2.9v2.5c0,1.4,0.9,2.5,2.1,2.9c0,4.1,3.4,7.5,7.6,7.5c4.2,0,7.6-3.4,7.6-7.6V32c0-2.2,1.7-3.9,3.9-3.9c2.2,0,3.9,1.7,3.9,3.9v2.5c-1.2,0.4-2.1,1.6-2.1,2.9c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1c0-1.4-0.9-2.5-2.1-2.9V32c0-3.3-2.7-6-6-6c-3.3,0-6,2.7-6,6v4.2c0,3-2.4,5.5-5.5,5.5c-3,0-5.4-2.4-5.5-5.4c1.2-0.4,2.1-1.6,2.1-2.9v-2.5c1.9-0.5,3.6-1.5,5-2.9c1.9-1.9,3.1-4.4,3.3-7.1c0.1-0.6-0.4-1-1-1v-9.4c0-1.7-1.4-3.1-3.1-3.1h-0.3c-0.4-0.6-1-1-1.8-1c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c0.8,0,1.4-0.4,1.8-1H25c0.6,0,1,0.5,1,1v9.4h-1.2c-0.6,0-1,0.5-1.1,1c-0.2,1.2-0.8,2.4-1.7,3.3c-1.1,1.1-2.7,1.8-4.3,1.8c-1.6,0-3.2-0.6-4.3-1.8c-0.9-0.9-1.5-2-1.7-3.3C11.6,20.3,11.2,19.8,10.6,19.8z M25.6,21.9h1.2c-0.4,1.7-1.3,3.3-2.5,4.5c-1.8,1.8-4.1,2.7-6.6,2.7c-2.5,0-4.9-1-6.6-2.7c-1.3-1.3-2.1-2.8-2.5-4.5h1.2c0.4,1.4,1.1,2.7,2.1,3.7c1.5,1.5,3.6,2.4,5.8,2.4c2.2,0,4.2-0.9,5.8-2.4C24.5,24.5,25.2,23.3,25.6,21.9z M40.6,36.5c0.6,0,1,0.5,1,1s-0.5,1-1,1s-1-0.5-1-1S40,36.5,40.6,36.5z M18.8,31.2v2.1c0,0.6-0.5,1-1,1s-1-0.5-1-1v-2.1H18.8z"/%3e%3c/svg%3e');
  }
}
.guide-container .top-guide-item .top-guide-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -130%;
  width: 120%;
  height: 100%;
  transform: skewX(-25deg);
  z-index: 2;
  background-color: aqua;
}
@media (hover: hover) and (pointer: fine) {
  .guide-container .top-guide-item .top-guide-btn:hover::before {
    animation: skewanime 0.5s forwards;
  }
}
@keyframes skewanime {
  100% {
    left: -10%;
  }
}
.guide-container .top-guide-item .top-guide-btn span {
  z-index: 3;
}
@media (hover: hover) and (pointer: fine) {
  .guide-container .top-guide-item .top-guide-btn:hover {
    color: var(--base);
  }
}
@media (hover: hover) and (pointer: fine) {
  .guide-container .top-guide-item .top-guide-btn:hover::before {
    background-color: var(--base);
  }
}
@media (hover: hover) and (pointer: fine) {
  .guide-container .top-guide-item .top-guide-btn:hover::after {
    margin-right: -0.3rem;
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M11,16.2l-1.4-1.4l2.8-2.8 L9.6,9.2L11,7.8l4.2,4.2L11,16.2z"/%3e%3c/svg%3e');
  }
}
.guide-container .top-guide-item .top-guide-btn::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231d3e87"%3e%3cpath d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M11,16.2l-1.4-1.4l2.8-2.8 L9.6,9.2L11,7.8l4.2,4.2L11,16.2z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  z-index: 3;
}
.guide-container .top-guide-item .top-guide-btn .top-guide-btn-title {
  color: var(--base);
}
@container (min-width: 600px) {
  .guide-container .top-guide-item .top-guide-btn .top-guide-btn-title.lg {
    font-size: 1.2rem;
  }
}
@media (hover: hover) and (pointer: fine) {
  .guide-container .top-guide-item .top-guide-btn:hover .top-guide-btn-title {
    color: #fff;
  }
}
@container (min-width: 760px) {
  .guide-container .top-guide-item {
    width: calc(33.333333% - 0.666666rem);
  }
  .guide-container .top-guide-item:nth-child(1), .guide-container .top-guide-item:nth-child(2) {
    width: calc(50% - 0.5rem);
  }
  .guide-container .top-guide-item .top-guide-btn {
    padding: 2rem 4.4rem 2rem 2.2rem;
  }
}

/* バナー */
.top-banner-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
}

.scrollbtn-inner {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
}

.scrollbtn-container {
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 100%;
}
@media screen and (min-width: 992px) {
  .scrollbtn-container {
    padding: 0;
  }
}

.scrollbtn-upper-content {
  position: relative;
  z-index: 1;
  margin-bottom: 2rem;
}
@media screen and (min-width: 992px) {
  .scrollbtn-upper-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.scrollbtn-upper-content .upper-heading {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 1rem;
  white-space: nowrap;
}
@media screen and (min-width: 992px) {
  .scrollbtn-upper-content .upper-heading {
    font-size: 2.25rem;
    margin-bottom: 0;
  }
}
.scrollbtn-upper-content .upper-heading .en {
  font-family: hind;
  color: #0e67b5;
  font-size: 1rem;
  display: inline-block;
  margin-left: 1.142857rem;
  margin-top: 0.5em;
}
@media screen and (min-width: 992px) {
  .scrollbtn-upper-content .upper-heading .en {
    margin-left: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .scrollbtn-upper-content .upper-desc {
    padding: 0 1rem 0 2rem;
    flex-grow: 1;
  }
}

.scrollbtn-slide {
  width: 100%;
  height: 100%;
}

.scrollbtn-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 208px !important;
  height: 117px !important;
  margin-right: 1rem;
}
@media screen and (min-width: 992px) {
  .scrollbtn-item {
    width: 390px !important;
    height: 216px !important;
  }
}
.scrollbtn-item:last-child {
  margin-right: 0;
}

.scrollbtn-link {
  font-size: 1rem;
  background-color: var(--gray-700);
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  position: relative;
  text-align: center;
  padding: 3.25rem 1rem;
  line-height: 1.4;
  overflow: hidden;
  transition: opacity 0.2s ease-out;
  /*&::before{
    content: '';
    width: 6rem;
    height: 6rem;
    background: #EDF9FF;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -3rem;
    transform: translateY(-50%);
  }
  &::after{
    content: '';
    background-image: set-svg($chev, $uno);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 1.25rem;
    height: 1.25rem;
  }*/
}
@media screen and (min-width: 992px) {
  .scrollbtn-link {
    font-size: 1.125rem;
  }
}
.scrollbtn-link span {
  position: relative;
  z-index: 1;
}
.scrollbtn-link:hover {
  color: #333;
  cursor: pointer;
}

.scrollbtn-tools {
  display: none;
  /*.swiper-button-next,
  .swiper-button-prev,
  .swiper-scrollbar,
  .swiper-scrollbar-horizontal {
    position: static !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    margin: 0 !important;
  }*/
}
@media screen and (min-width: 992px) {
  .scrollbtn-tools {
    display: flex;
    align-items: center;
  }
}
.scrollbtn-tools .swiper-button-next,
.scrollbtn-tools .swiper-button-prev {
  width: 2.75rem;
}
.scrollbtn-tools .swiper-button-next {
  right: 19px !important;
}
.scrollbtn-tools .swiper-button-prev {
  left: 19px !important;
}
.scrollbtn-tools .swiper-button-next::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M11,16.2l-1.4-1.4l2.8-2.8 L9.6,9.2L11,7.8l4.2,4.2L11,16.2z"/%3e%3c/svg%3e');
  background-size: contain;
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
  display: inline-block;
}
.scrollbtn-tools .swiper-button-prev::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M11,16.2l-1.4-1.4l2.8-2.8 L9.6,9.2L11,7.8l4.2,4.2L11,16.2z"/%3e%3c/svg%3e');
  background-size: contain;
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
  display: inline-block;
  transform: rotate(180deg);
}
.scrollbtn-tools .swiper-scrollbar {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.5);
}
.scrollbtn-tools .swiper-scrollbar.swiper-scrollbar-horizontal {
  height: 3px;
  margin-left: 1rem !important;
}
.scrollbtn-tools .swiper-scrollbar-drag {
  background: #0e67b5;
  border-radius: 0;
}

.split-hero-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 7rem;
}
@media screen and (min-width: 992px) {
  .split-hero-wrapper {
    flex-direction: row;
  }
}

.split-hero-content {
  width: 100%;
  height: 40vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 1rem;
}
@media screen and (min-width: 992px) {
  .split-hero-content {
    width: 50%;
    height: 40vh;
  }
}
.split-hero-content + .split-hero-content {
  margin-top: 10rem;
}
@media screen and (min-width: 992px) {
  .split-hero-content + .split-hero-content {
    margin-top: 0;
  }
}
.split-hero-content .panel-item {
  padding: 1.785714rem 1.5rem;
  border-radius: 0.5rem;
  background: #fff;
  margin-bottom: -7rem;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  width: 100%;
}
@media screen and (min-width: 992px) {
  .split-hero-content .panel-item {
    width: 90%;
    padding: 2rem;
  }
}
@media screen and (min-width: 1200px) {
  .split-hero-content .panel-item {
    width: 80%;
  }
}
.split-hero-content .panel-heading {
  font-size: 1.571428rem;
  font-weight: 400;
  text-align: center;
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 1.785714rem;
}
@media screen and (min-width: 992px) {
  .split-hero-content .panel-heading {
    font-size: 1.75rem;
    margin-bottom: 2rem;
  }
}
.split-hero-content .panel-heading:after {
  content: "";
  width: 3rem;
  height: 2px;
  background: #595959;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.split-hero-content .panel-heading.base {
  color: #1d3e87;
}
.split-hero-content .panel-heading.base:after {
  background: #1d3e87;
}
.split-hero-content .panel-heading.uno {
  color: #0e67b5;
}
.split-hero-content .panel-heading.uno:after {
  background: #0e67b5;
}

.top-bnr-wrapper {
  padding: 4.5rem 0;
  position: relative;
}
@media screen and (min-width: 992px) {
  .top-bnr-wrapper {
    padding: 7.75rem 0;
  }
}
.top-bnr-wrapper::before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("/images/top/top-bnr-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 0;
  opacity: 0.4;
}
.top-bnr-wrapper::after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #92c4ef, #d6f1ff);
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 1;
  mix-blend-mode: multiply;
  opacity: 0.8;
}
.top-bnr-wrapper > * {
  position: relative;
  z-index: 2;
}

.top-bnr-link {
  display: inline-block;
  background: #fff;
  border-radius: 0.5em;
}
.top-bnr-link img {
  transition: opacity 0.2s ease-out;
}
@media (hover: hover) {
  .top-bnr-link:hover img {
    opacity: 0.7;
  }
}

.bnr-list {
  padding: 2rem 0;
}
@media screen and (min-width: 992px) {
  .bnr-list {
    padding: 4.5rem 0;
  }
}
.bnr-list img {
  transition: opacity 0.2s ease-out;
}